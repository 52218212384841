import React, { useEffect, useState, useRef } from 'react';
import OpenAI from 'openai';
import SendIcon from '@mui/icons-material/Send';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Messages } from './Messages';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'bootstrap/dist/css/bootstrap.min.css';
import './VectorSearchs.css';
import { useLocation } from 'react-router-dom';


export const VectorSearchs = ({ quizTitle }) => {
  const chatContainerRef = useRef(null);
  const location = useLocation();
  const [isWaiting, setIsWaiting] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [thread, setThread] = useState(null);
  const [openai, setOpenai] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showMainSuggestion, setShowMainSuggestion] = useState(true);
  const [isMainSuggestionClicked, setIsMainSuggestionClicked] = useState(false);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);


  const assistant = 'asst_Sor2JHvEpvPQBv3xf1kTDtUP';
  const REACT_APP_OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

  const getMainSuggestionText = (title) =>
    `Explain ${title}`;

  const mainSuggestion = {
    id: 0,
    text: getMainSuggestionText(quizTitle),
  };

  const getMainSuggestionText1 = (title) =>
    `Explain ${title}`;

  const mainSuggestion1 = {
    id: 0,
    text: getMainSuggestionText1(quizTitle),
  };


  const suggestionToUseForMain = location.pathname.includes('standard') ? mainSuggestion1 : mainSuggestion;



  const suggestions = [
    {
      id: 1,
      text: `Generate  "match the following using table" questions and answers for title "${quizTitle}".`,
    },
    {
      id: 2,
      text: `Generate a assertion , reason NEET hard type MCQ questions and answers for title "${quizTitle}".`,
    },
    {
      id: 3,
      text: `Provide a step-by-step solution for the NEET numerical problem under "${quizTitle}"`,
    },
    {
      id: 4,
      text: `Generate 5 common definition questions and answers for NEET title "${quizTitle}".`,
    },
    {
      id: 5,
      text: `Generate  mcqs question related  to the NEET which give option and answer  exam topic "${quizTitle}".`,
    },
  ];


  const suggestions2 = [
    {
      id: 1,
      text: ` Generate very short question , answer related   to the NCRT exam topic   "${quizTitle}".`,
    },
    {
      id: 2,
      text: `Generate long question , answer related  to the NCRT exam topic  "${quizTitle}".`,
    },
    {
      id: 3,
      text: `Generate  "match the following using table" questions and answers for NEET title  "${quizTitle}"`,
    },
    {
      id: 4,
      text: `Generate a assertion  reason NEET hard type MCQ questions and answers for NEET title "${quizTitle}".`,
    },
    {
      id: 5,
      text: `Provide a step-by-step solution for the NEET numerical problem under "${quizTitle}".`,
    },
    {
      id: 6,
      text: `Generate  mcqs question realted  to the NEET which give option and answer  exam topic "${quizTitle}".`,
    },
  ];


  const suggestionsToUse = location.pathname.includes('standard') ? suggestions2 : suggestions;

  const detailedPrompt = `Generate "Match the Following" multiple-choice questions and answers for the topic '${quizTitle}'.
For matching questions, format the response in an HTML table where each row contains a pair of matched items and is mimatched. and in the answer show the right match in the match coloum table  not use the answer which give outside the table:

<p>
  <table style="width: 70%; border-collapse: collapse; border: 1px solid #ddd;">
    <thead>
      <tr style="background-color: #f4f4f4; border-bottom: 1px solid #ddd;">
        <th style="padding: 8px; text-align: left; border-right: 1px solid #ddd;">Items</th>
        <th style="padding: 8px; text-align: left;">Incorrect Match</th>
      </tr>
    </thead>
    <tbody>
      <tr style="border-bottom: 1px solid #ddd;">
        <td style="padding: 8px;">Item 1</td>
        <td style="padding: 8px;">Option 1</td>
      </tr>
      <tr style="border-bottom: 1px solid #ddd;">
        <td style="padding: 8px;">Item 2</td>
        <td style="padding: 8px;">Option 2</td>
      </tr>
      <tr style="border-bottom: 1px solid #ddd;">
        <td style="padding: 8px;">Item 3</td>
        <td style="padding: 8px;">Option 3</td>
      </tr>
      <tr style="border-bottom: 1px solid #ddd;">
        <td style="padding: 8px;">Item 4</td>
        <td style="padding: 8px;">Option 4</td>
      </tr>
      <tr style="border-bottom: 1px solid #ddd;">
        <td style="padding: 8px;">Item 5</td>
        <td style="padding: 8px;">Option 5</td>
      </tr>
      <tr>
        <td style="padding: 8px;">Item 6</td>
        <td style="padding: 8px;">Option 6</td>
      </tr>
    </tbody>
  </table>
</p>

<p>Correct Matching Pairs:</p>
<ul>
  <li>Item 1 - Option 3</li>
  <li>Item 2 - Option 5</li>
  <li>Item 3 - Option 1</li>
  <li>Item 4 - Option 6</li>
  <li>Item 5 - Option 2</li>
  <li>Item 6 - Option 4</li>
</ul>

Make sure that the table is complete, and both the incorrect and correct matches use only the given options.
`;
  

  const detailedCommonDefinitioPrompt = ` Generate 5 common definition questions and answers '${quizTitle}'. Format the response in a neat HTML table with three columns: "Question", "Answer". Ensure that the table includes examples like below:
<table style="width: 100%; border-collapse: collapse; margin-bottom: 20px;">
        <thead>
            <tr>
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: #f4f4f4; font-weight: bold;">Question</th>
                <th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: #f4f4f4; font-weight: bold;">Answer</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td style="border: 1px solid #ddd; padding: 8px; background-color: #fff; font-weight: bold;">Question 1</td>
                <td style="border: 1px solid #ddd; padding: 8px; background-color: #fff; font-style: italic;">Answer 1</td>
            </tr>
            <tr>
                <td style="border: 1px solid #ddd; padding: 8px; background-color: #fff; font-weight: bold;">Question 2</td>
                <td style="border: 1px solid #ddd; padding: 8px; background-color: #fff; font-style: italic;">Answer 2</td>
            </tr>
            <tr>
                <td style="border: 1px solid #ddd; padding: 8px; background-color: #fff; font-weight: bold;">Question 3</td>
                <td style="border: 1px solid #ddd; padding: 8px; background-color: #fff; font-style: italic;">Answer 3</td>
            </tr>
            <tr>
                <td style="border: 1px solid #ddd; padding: 8px; background-color: #fff; font-weight: bold;">Question 4</td>
                <td style="border: 1px solid #ddd; padding: 8px; background-color: #fff; font-style: italic;">Answer 4</td>
            </tr>
            <tr>
                <td style="border: 1px solid #ddd; padding: 8px; background-color: #fff; font-weight: bold;">Question 5</td>
                <td style="border: 1px solid #ddd; padding: 8px; background-color: #fff; font-style: italic;">Answer 5</td>
            </tr>
        </tbody>
    </table>
 
.`;

  const techartext = ' Teaching Plan.'
  // New prompt for Teacher's Plan
  const teacherPlanPrompt = `Generate teaching plan on the topic "${quizTitle}" for NCERT Grade 10 .`;

  const Worksheettext = ' Worksheet Plan.'
  // New prompt for Teacher's Plan
  const WorksheetPrompt = `Generate worksheets for practice and homework on the topic ""${quizTitle}"" .`;






  useEffect(() => {
    const initChatBot = async () => {
      const openaiInstance = new OpenAI({
        apiKey: REACT_APP_OPENAI_API_KEY,
        dangerouslyAllowBrowser: true,
      });

      try {
        const threadInstance = await openaiInstance.beta.threads.create({
          tool_resources: {
            file_search: {
              vector_store_ids: ['vs_FX51jgwJZJxNPwyeUo21Jprq'],
            },
          },
        });

        setOpenai(openaiInstance);
        setThread(threadInstance);
      } catch (error) {
        console.error('Error initializing ChatBot:', error);
      }
    };

    initChatBot();
  }, [REACT_APP_OPENAI_API_KEY]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      setShowScrollTopButton(scrollPosition > 300);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };





  const createNewMessage = (content, isUser) => ({
    isUser,
    content,
  });

  const handleSendMessage = async (messageContent = input) => {
    if (messageContent.trim() === '') return;

    const newMessages = [...messages, createNewMessage(messageContent, true)];
    setMessages(newMessages);
    setInput('');
    setShowMainSuggestion(false);
    setShowSuggestions(false);

    try {
      let promptToSend = messageContent;
      if (messageContent === suggestions.find((s) => s.id === 1)?.text) {
        promptToSend = detailedPrompt;
      } else if (messageContent === suggestions.find((s) => s.id === 4)?.text) {
        promptToSend = detailedCommonDefinitioPrompt;
      } else if (messageContent === suggestions2.find((s) => s.id === 3)?.text) {
        promptToSend = detailedPrompt;
      } else if (messageContent === techartext) {
        promptToSend = teacherPlanPrompt;
      }else if (messageContent ===  Worksheettext) {
        promptToSend =  WorksheetPrompt;
      }

      await openai.beta.threads.messages.create(thread.id, {
        role: 'user',
        content: promptToSend,
      });

      const run = await openai.beta.threads.runs.create(thread.id, {
        assistant_id: assistant,
      });

      let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);

      while (response.status === 'in_progress' || response.status === 'queued') {
        setIsWaiting(true);
        await new Promise((resolve) => setTimeout(resolve, 500));
        response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
      }

      setIsWaiting(false);

      const messageList = await openai.beta.threads.messages.list(thread.id);

      const lastMessage = messageList.data
        .filter((message) => message.run_id === run.id && message.role === 'assistant')
        .pop();




      if (lastMessage) {
        // Display the generated HTML content

        setMessages([
          ...newMessages,
          createNewMessage(lastMessage.content[0]['text'].value, false),

        ]);

        setShowSuggestions(true);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setIsWaiting(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleSuggestionClick = (suggestionText) => {
    handleSendMessage(suggestionText);
  };

  const handleMainSuggestionClick = () => {
    handleSendMessage(suggestionToUseForMain.text)
    setIsMainSuggestionClicked(true);
  };


  // Handler for Teacher's Plan button
  const handleTeacherPlanClick = () => {
    handleSendMessage(teacherPlanPrompt);

  };

   // Handler for Teacher's Plan button
   const WorksheetClick = () => {
    handleSendMessage(WorksheetPrompt);

  };


  const downloadChatAsPDF = (chat, title) => {
    const chatContainer = document.getElementById('chat-container');

    html2canvas(chatContainer, { scale: 3, useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');

      const imgWidth = 230; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const borderWidth = 2; // Width of the border

      let heightLeft = imgHeight;
      let position = 0;

      // Watermark settings
      const watermarkText = 'Classroom AI';
      const watermarkX = pdf.internal.pageSize.getWidth() / 2;
      const watermarkY = pdf.internal.pageSize.getHeight() / 2;
      const watermarkAngle = 45;

      // Function to add watermark
      const addWatermark = () => {
        pdf.setTextColor(240, 240, 240); // Light grey color for watermark
        pdf.setFontSize(50);
        pdf.text(watermarkText, watermarkX, watermarkY, {
          angle: watermarkAngle,
          align: 'center',
          baseline: 'middle'
        });
      };

      // Function to draw border around the page
      const drawBorder = () => {
        pdf.setLineWidth(borderWidth); // Set the line width for the border
        pdf.rect(borderWidth / 2, borderWidth / 2, pdf.internal.pageSize.width - borderWidth, pdf.internal.pageSize.height - borderWidth); // Draw the border
      };

      // First Page
      addWatermark(); // Add watermark first
      pdf.addImage(imgData, 'PNG', borderWidth, borderWidth, imgWidth - 2 * borderWidth, imgHeight, '', 'FAST');
      drawBorder(); // Draw border around the whole page

      heightLeft -= pageHeight;

      // Additional Pages
      while (heightLeft > 0) {
        pdf.addPage();
        addWatermark(); // Add watermark on each page before image
        position = heightLeft - imgHeight;

        pdf.addImage(imgData, 'PNG', borderWidth, position, imgWidth - 2 * borderWidth, imgHeight, '', 'FAST');
        drawBorder(); // Draw border around the whole page

        heightLeft -= pageHeight;
      }

      // Save the PDF
      pdf.save(`${title || 'chat_conversation'}.pdf`);
    });
  };



  return (
    <MathJaxContext>
      <div className="container-fluid bg-dark text-light rounded p-3" style={{ minHeight: '80vh' }}>
        <div className="row mb-3">
          <div className="col">
            <Messages
              message={{
                content: "Hi, I'm your NEET assistant. How can I help you?",
                isUser: false,
              }}
            />
          </div>
        </div>
        {showMainSuggestion && (
          <div className="row mb-3">
            <div className="col">
              <button
                type="button"
                className="main-suggestion-btn"
                onClick={handleMainSuggestionClick}
              >
                {suggestionToUseForMain.text}
              </button>
            </div>
          </div>
        )}
        {location.pathname.includes('standard') && (
          <div className="row mb-3">
            <div className="col-md-4">
              <button
                type="button"
                className="btn btn-outline-primary w-100 text-light font-weight-bold"
                onClick={handleTeacherPlanClick}
              >
                Teaching plan
              </button>
            </div>
          </div>
        )}
         <div className="row mb-3">
            <div className="col-md-4">
              <button
                type="button"
                className="btn btn-outline-primary w-100 text-light font-weight-bold"
                onClick={ WorksheetClick}
              >
                 Worksheet
              </button>
            </div>
          </div>
      
        <div   id={`chat-container`} ref={chatContainerRef}> 

        
        {messages.map((message, index) => (
          <div className="row mb-2" key={index}>
            <div className={`col text-${message.isUser ? 'end' : 'start'}`}>
          
              <MathJax>
                <Messages
                  key={index}
                  message={{
                    content: <div
                   
                      dangerouslySetInnerHTML={{
                        __html: message.content
                          .replace(/\\n/g, '')
                          .replace(/^(#{1,6})\s*(.+)$/gm, (match, hashes, headerText) => {
                            const level = hashes.length;
                            return `<h${level}>${headerText}</h${level}>`;
                          })
                          .replace(/\*\*([^*]+)\*\*/g, '<strong>$1</strong>')
                          .replace(/ {2,}/g, ' ')
                          .replace(/>\s+</g, '><')
                          .replace(/\n/g, '<br>')
                          .replace(/\n\s*/g, '')
                          .trim()
                        ,
                      }}
                      
                    />,
                    isUser: message.isUser,
                    
                  }}
                
                />
              </MathJax>
          
              </div>
          </div>
        ))}
        </div>
        {showSuggestions && isMainSuggestionClicked && (
          <div className="row mb-2">
            {suggestionsToUse.map((suggestion) => (
              <div className="col-auto mb-2" key={suggestion.id}>
                <button
                  type="button"
                  className="main-suggestion-btn"
                  onClick={() => handleSuggestionClick(suggestion.text)}
                >
                  {suggestion.text}
                </button>
              </div>
            ))}
          </div>
        )}
        {isWaiting && (
          <div className="row mb-3">
            <div className="col text-center">
              <div className="typing-indicator">
                <span>typing...</span> {/* Display "typing..." */}
              </div>
            </div>
          </div>
        )}
         <div className="row">
          <div className="col">
            <input
              type="text"
              placeholder="Ask your question here..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyPress}
              className="form-control mb-2"
            />
            <button
              type="button"
              className="btn btn-primary me-2"
              onClick={() => handleSendMessage()}
            >
              <SendIcon /> Send
            </button>
           
            <button
              type="button"
              className="btn btn-secondary"
              onClick={downloadChatAsPDF}
            >
              <FileDownloadIcon /> Download PDF
            </button>
            {showScrollTopButton && (
              <button
                className="scroll-to-top-btn"
                onClick={scrollToTop}
              >
                <div className="top-arrow"></div>
              </button>
            )}
          </div>
        </div>
      </div>
    </MathJaxContext>
  );

};


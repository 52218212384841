import React, { useState } from 'react';
import { Box, Button, Container, Grid } from '@mui/material';
import { styled } from '@mui/system';
import WatchItNow from './WatchItNow';
import DoItYourself from './DoItYourself';

const scienceVideoSlides = [
  {
    url: 'https://www.youtube.com/embed/ICnIKjfQ_ko?rel=0',
    title: 'Watch It Video 1',
  },
  {
    url: 'https://www.youtube.com/embed/91103asM15o?rel=0',
    title: 'Watch It Video 2',
  },
  {
    url: 'https://www.youtube.com/embed/swsdt64sYok?rel=0',
    title: 'Watch It Video 3',
  },
];

const mathsVideoSlides = [
  {
    url: 'https://www.youtube.com/embed/0jW2esmj2gI?rel=0',
    title: 'Watch It Video 1',
  },
  {
    url: 'https://www.youtube.com/embed/7i3ivI9-PA4?rel=0',
    title: 'Watch It Video 2',
  },
  {
    url: 'https://www.youtube.com/embed/BgMhu0ofc-U?rel=0',
    title: 'Watch It Video 3',
  },
];

const neetVideoSlides = [
  {
    url: 'https://www.youtube.com/embed/ICnIKjfQ_ko?rel=0',
    title: 'Watch It Video 1',
  },
  {
    url: 'https://www.youtube.com/embed/91103asM15o?rel=0',
    title: 'Watch It Video 2',
  },
];

const StyledButton = styled(Button)(({ selected, side }) => ({
  textTransform: 'none',
  padding: '10px 30px',
  borderRadius: side === 'left' ? '10px 0 0 10px' : side === 'right' ? '0 10px 10px 0' : '0',
  border: '1px solid #ffffff',
  color: selected ? '#fe6f61' : '#808080',
  backgroundColor: selected ? '#ffffff33' : 'transparent',
  '&:hover': {
    backgroundColor: selected ? '#ffffff33' : '#ffffff10',
  },
  transition: 'background-color 0.3s ease',
}));

export const ProductInteractiveModels = () => {
  const [selectedSubject, setSelectedSubject] = useState('science');
  const [selectedTab, setSelectedTab] = useState('watchitnow');

  const getVideoSlides = () => {
    if (selectedSubject === 'science') {
      return scienceVideoSlides;
    } else if (selectedSubject === 'maths') {
      return mathsVideoSlides;
    } else if (selectedSubject === 'neet') {
      return neetVideoSlides;
    } else {
      return; // Optional: handle any other cases
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
      }}
    >
      <Box
        variant='contained'
        sx={{
          backgroundColor: '#fe6f61',
          borderRadius: '20px',
          fontSize: '20px',
          padding: '10px 20px',
          '&:hover': {
            backgroundColor: '#FF1F46',
          },
        }}
      >
        INTERACTIVE MODELS
      </Box>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 3,
          borderRadius: '25px',
          padding: '5px',
        }}
      >
        <StyledButton
          sx={{
            fontSize: { xs: '16px', md: '20px' },
          }}
          selected={selectedSubject === 'science'}
          onClick={() => setSelectedSubject('science')}
          side='left'
        >
          SCIENCE
        </StyledButton>
        <StyledButton
          sx={{
            fontSize: { xs: '16px', md: '20px' },
          }}
          selected={selectedSubject === 'maths'}
          onClick={() => setSelectedSubject('maths')}
        >
          MATHS
        </StyledButton>
        <StyledButton
          sx={{
            fontSize: { xs: '16px', md: '20px' },
          }}
          selected={selectedSubject === 'neet'}
          onClick={() => setSelectedSubject('neet')}
          side='right'
        >
          NEET
        </StyledButton>
      </Container>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mt={3}>
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '25px',
            padding: '5px',
          }}
          mt={3}
        >
          <StyledButton
            sx={{
              fontSize: { xs: '16px', md: '20px' },
            }}
            selected={selectedTab === 'watchitnow'}
            onClick={() => setSelectedTab('watchitnow')}
            side='left'
          >
            WATCH IT NOW!
          </StyledButton>
          <StyledButton
            sx={{
              fontSize: { xs: '16px', md: '20px' },
            }}
            selected={selectedTab === 'doityourself'}
            onClick={() => setSelectedTab('doityourself')}
            side='right'
          >
            DO IT YOURSELF!
          </StyledButton>
        </Container>
      </Box>
      <Box mt={3}>
        {selectedTab === 'watchitnow' && (
          <Grid item xs={12} sm={9} md={8}>
            <WatchItNow videoSlides={getVideoSlides()} />
          </Grid>
        )}
        {selectedTab === 'doityourself' && (
          <Grid item xs={12} sm={9} md={6}>
            <DoItYourself selectedSubject={selectedSubject} />
          </Grid>
        )}
      </Box>
    </Box>
  );
};

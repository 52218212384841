import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography, Box, Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import physics from '_assets/images/Physics.webp';
import chemistry from '_assets/images/Chemistry.webp';
import bio from '_assets/images/Biology.webp';
import NeetTest from '_assets/images/NEET_TEST_SERIES.png';
import './../individualDashboard/individualHome.css'
import { MockAnalysisNeet } from './MockAnalysisNeet';
import { NeetChapterAnalysis } from './NeetChapterAnalysis';
import { useSelector } from 'react-redux';
import NeetIcon from '_assets/images/neet_preparation.png';
import MockIcon from '_assets/images/mock_analysis.png';

// Define your subjects array
const subjects = [
  { subject: 'Physics', image: physics },
  { subject: 'Chemistry', image: chemistry },
  { subject: 'Biology', image: bio },
  { subject: 'All_Subjects', image: NeetTest },
];

const NeetHome = () => { // Destructure isNEET from props
  const [activeSection, setActiveSection] = useState('NEET PREPARATION');
  const navigate = useNavigate(); // Initialize useNavigate
  const user = useSelector((x) => x.auth.value);
  const { plan_courses, plan_start_date, plan_end_date } = user || {};

  const plan_course_name = plan_courses.replace(/_/g, ' ');

  const handleNEETClick = (subject) => {
    if (subject === 'All_Subjects') {
      navigate(`/dashboard/NEET_TEST/subject/${subject}`) // Different location for NEET Test
    } else {
      navigate(`/dashboard/NEET/subject/${subject}`);
    }
  };

  return (
    <Box
      className='containerBox'
      sx={{
        '@media (max-width: 600px)': {
          flexDirection: 'column',
        },
      }}
    >
      {/* Left Panel */}
      <Paper className='leftPanel'
      >
        <Typography variant='h6' className='mb-3'>
          {plan_course_name || 'Course Details'} {/* Dynamically displaying the class */}
        </Typography>
        <Typography variant='body1' className='mb-3'>
          Course Duration
          <br />
          {plan_start_date ? new Date(plan_start_date).toLocaleDateString() : 'Start Date'} -
          {plan_end_date ? new Date(plan_end_date).toLocaleDateString() : 'End Date'}
        </Typography>
        <Box className='buttonBox'>
          <Button
            variant={activeSection === 'NEET PREPARATION' ? 'contained' : 'outlined'}
            className='leftPanelButton'
            sx={{
              backgroundColor: activeSection === 'NEET PREPARATION' ? '#e74c3c' : 'transparent',
              color: '#fff',
            }}
            onClick={() => setActiveSection('NEET PREPARATION')}
          >
            <img
              src={NeetIcon}
            />
            NEET PREPARATION
          </Button>
          <Button
            variant={activeSection === 'MOCK TEST ANALYSIS' ? 'contained' : 'outlined'}
            className='leftPanelButton mb-4'
            sx={{
              backgroundColor: activeSection === 'MOCK TEST ANALYSIS' ? '#e74c3c' : 'transparent',
              color: '#fff',
            }}
            onClick={() => setActiveSection('MOCK TEST ANALYSIS')}
          >
            <img
              src={MockIcon}
            />
            MOCK TEST ANALYSIS
          </Button>
        </Box>
      </Paper>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, padding: 6 }}>
        {activeSection === 'NEET PREPARATION' ? (
          <Grid container spacing={4} justifyContent="center">
            {subjects.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} sx={{ marginTop: "5px" }}>
                <Card
                  className='cardBox'
                  onClick={() => handleNEETClick(item.subject)}
                >
                  <CardContent sx={{ textAlign: 'center', padding: '0' }}>
                    <Box>
                      <img
                        src={item.image}
                        alt={item.subject}
                        style={{ width: '100%', height: '250px', maxWidth: '250px', borderRadius: "15px" }}
                      />
                    </Box>
                    <Typography variant="h6" sx={{ marginBottom: 0, color: "white" }}>
                      Subject: {item.subject}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="h4" color="#fff">
            <NeetChapterAnalysis />
            <MockAnalysisNeet />
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export { NeetHome };

import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { fetchWrapper } from '_helpers';
import './mock.css';
import { useSelector } from 'react-redux';
import { ResponsiveContainer } from 'recharts';

const MockPerformanceAnalysis = () => {
  const user = useSelector((x) => x.auth.value);
  const [dropdown1Value, setDropdown1Value] = useState('');
  const [dropdown2Value, setDropdown2Value] = useState('');
  const [dropdown3Value, setDropdown3Value] = useState('');
  const [data, setData] = useState([]);
  const [uniqueSubjects, setUniqueSubjects] = useState([]);
  const [sectionTitlesArray, setSectionTitlesArray] = useState([]);
  const [filteredSubsectionsWithQuiz, setFilteredSubsectionsWithQuiz] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false); // State for controlling popup visibility
  const [popupContent, setPopupContent] = useState(''); // State for popup content

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchWrapper.get(
          `${process.env.REACT_APP_API_URL}/api/v1/getMockPerformance`,
        );
        const filteredData = response.filter(item => item.student_email === user.email);
        setData(filteredData);
        const subjects = [...new Set(filteredData.map((item) => item.subject))];
        setUniqueSubjects(subjects);
      } catch (error) {
        console.error('Error fetching performance data:', error);
      }
    };

    fetchData();
  }, []);

  const handleHelpMeClick = async (subsection_name) => {
    try {
      const res = await fetchWrapper.get(
        `${process.env.REACT_APP_API_URL}/api/v1/getMockSubsectionInfo`,
      );
      const matchingSubsection = res.find((item) => {
        const mainTitle = item.sub_section_title.split(':')[0].trim();
        return mainTitle === subsection_name;
      });

      if (matchingSubsection) {
        // Set popup content
        setPopupContent(
          `/dashboard/NEET/subject/${matchingSubsection.subject.toLowerCase()}/chapter/${matchingSubsection.chapter_id
          }/section/${matchingSubsection.section_id}/subsection/${matchingSubsection.sub_section_id
          }`,
        );
        // Open popup
        setPopupOpen(true);
      } else {
        console.error('No matching subsection found');
      }
    } catch (error) {
      console.error('Error fetching subsection info:', error);
    }
  };

  const handleDropdown1Change = (event) => {
    const selectedSubject = event.target.value;
    setDropdown1Value(selectedSubject);

    const sections = [
      ...new Set(
        data
          .filter((item) => item.subject === selectedSubject && item.section_name)
          .map((item) => item.section_name),
      ),
    ];
    setSectionTitlesArray(sections);

    setDropdown2Value('');
    setDropdown3Value('');
    setFilteredSubsectionsWithQuiz([]);
  };

  const handleDropdown2Change = (event) => {
    const selectedSection = event.target.value;
    setDropdown2Value(selectedSection);

    const subsections = [
      ...new Set(
        data
          .filter(
            (item) =>
              item.subject === dropdown1Value &&
              item.section_name === selectedSection &&
              item.subsection_name,
          )
          .map((item) => item.subsection_name),
      ),
    ];
    setFilteredSubsectionsWithQuiz(subsections);

    setDropdown3Value('');
  };

  const handleDropdown3Change = (event) => {
    const selectedSubsection = event.target.value;
    setDropdown3Value(selectedSubsection);
  };

  const aggregateData = (filteredData) => {
    return filteredData.reduce((acc, item) => {
      const level = item.test_level;
      if (!acc[level]) {
        acc[level] = {
          subject: item.subject,
          section_name: item.section_name,
          subsection_name: item.subsection_name,
          totalQuestions: 0,
          correctAnswers: 0,
          wrongAnswers: 0,
          created_at: item.created_at,
        };
      }
      acc[level].totalQuestions++;
      if (item.right_wrong === 1) {
        acc[level].correctAnswers++;
      } else if (item.right_wrong === 0) {
        acc[level].wrongAnswers++;
      }
      return acc;
    }, {});
  };

  const aggregatedData = () => {
    let filtered = [];
    if (dropdown1Value && dropdown2Value && dropdown3Value) {
      filtered = data.filter(
        (item) =>
          item.subject === dropdown1Value &&
          item.section_name === dropdown2Value &&
          item.subsection_name === dropdown3Value,
      );
    } else if (dropdown1Value && dropdown2Value) {
      filtered = data.filter(
        (item) => item.subject === dropdown1Value && item.section_name === dropdown2Value,
      );
    } else if (dropdown1Value) {
      filtered = data.filter((item) => item.subject === dropdown1Value);
    }
    return aggregateData(filtered);
  };

  const aggregatedArray = Object.keys(aggregatedData()).map((level) => ({
    test_level: level,
    ...aggregatedData()[level],
  }));

  const chartData = [
    {
      data: aggregatedArray.map(item => item.correctAnswers),
      label: 'Correct Answers',
      color: '#05a70c'
    },
    {
      data: aggregatedArray.map(item => item.wrongAnswers),
      label: 'Wrong Answers',
      color: '#c81a0df5'
    }
  ];

  const xLabels = aggregatedArray.map((item) => item.test_level);

  return (
    <div>
      <div className='container-fluid'>
        <div className='m-3'>
          <div className='h3'>Mockup Performance Analysis</div>
          <div className='h6 intro' style={{ height: '100%', background: '#ccf2ff' }}>
            My Performance Analysis
          </div>
        </div>
      </div>
      <Box className='d-flex mt-4'>
        <FormControl fullWidth className='p-2'>
          <InputLabel>Subject</InputLabel>
          <Select value={dropdown1Value} onChange={handleDropdown1Change}>
            <MenuItem value=''>None</MenuItem>
            {uniqueSubjects.map((subject, index) => (
              <MenuItem key={index} value={subject}>
                {subject}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth className='p-2'>
          <InputLabel>Section</InputLabel>
          <Select
            value={dropdown2Value}
            onChange={handleDropdown2Change}
            disabled={!dropdown1Value}
          >
            <MenuItem value=''>None</MenuItem>
            {sectionTitlesArray.map((title, index) => (
              <MenuItem key={index} value={title}>
                {title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box className="d-flex">
        <FormControl fullWidth className="p-2 w-50">
          <InputLabel>Subsection</InputLabel>
          <Select
            value={dropdown3Value}
            onChange={handleDropdown3Change}
            disabled={!dropdown1Value || !dropdown2Value}
          >
            <MenuItem value=''>None</MenuItem>
            {filteredSubsectionsWithQuiz.map((subsection, index) => (
              <MenuItem key={index} value={subsection}>
                {subsection}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {(dropdown1Value || dropdown2Value || dropdown3Value) && (
        <>
          <Box className="chart-container">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart width={1000} height={300} series={chartData} xAxis={[{ data: xLabels, scaleType: 'band' }]} yAxis={[{ min: 0, max: Math.max(...aggregatedArray.map(item => item.totalQuestions)) }]} />
            </ResponsiveContainer>
          </Box>
          <Box className="table-container mt-4 m-2">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="performance table">
                <TableHead>
                  <TableRow>
                    <TableCell>Test Level</TableCell>
                    {dropdown1Value && (
                      <>
                        <TableCell align='center'>Subject</TableCell>
                        {dropdown2Value && <TableCell align='center'>Section</TableCell>}
                        {dropdown3Value && <TableCell align='center'>Subsection</TableCell>}
                      </>
                    )}
                    <TableCell align='center'>Total Questions</TableCell>
                    <TableCell align='center'>Correct Answers</TableCell>
                    <TableCell align='center'>Wrong Answers</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {aggregatedArray.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component='th' scope='row'>
                        {row.test_level}
                      </TableCell>
                      {dropdown1Value && (
                        <>
                          <TableCell align='center'>{row.subject}</TableCell>
                          {dropdown2Value && (
                            <TableCell align='center'>{row.section_name || '-'}</TableCell>
                          )}
                          {dropdown3Value && (
                            <TableCell align='center'>{row.subsection_name || '-'}</TableCell>
                          )}
                        </>
                      )}
                      <TableCell align='center'>{row.totalQuestions}</TableCell>
                      <TableCell align='center'>{row.correctAnswers}</TableCell>
                      <TableCell align='center'>{row.wrongAnswers}</TableCell>
                      {dropdown1Value && dropdown2Value && dropdown3Value && (
                        <TableCell align='center'>
                          <Button onClick={() => handleHelpMeClick(row.subsection_name)}>
                            HELP ME!
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}

      {/* Popup Overlay */}
      {popupOpen && (
        <div className='popupOverlay'>
          <div className='popupContainer'>
            <button
              className='btn btn-primary btn-circle btn-sm backButton'
              onClick={() => setPopupOpen(false)}
            >
              X
            </button>
            <iframe className='popupIframe' src={popupContent}></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default MockPerformanceAnalysis;

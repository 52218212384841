import React, { useState, useRef, useEffect } from 'react';
import { Typography, TextField, Container, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Logo from '../../_assets/images/classroom-ai.gif';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { fetchWrapper } from '_helpers';
import { useNavigate } from 'react-router-dom';

export { MobileLogin };

function MobileLoginForm({ onSubmit, step }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        mobile: Yup.string()
          .required('Mobile number is required')
          .matches(/^\d{10}$/, 'Enter a valid 10-digit mobile number'),
      }),
    ),
  });

  return step === 1 ? (
    <>
      <Typography
        variant='body1'
        sx={{
          marginBottom: '18px',
          textAlign: 'center',
          fontSize: '16px',
        }}
        gutterBottom
      >
        Enter your mobile number to continue your journey
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label='Student Mobile'
          type='tel'
          variant='outlined'
          fullWidth
          {...register('mobile')}
          error={!!errors.mobile}
          helperText={errors.mobile?.message}
          InputLabelProps={{ shrink: true }}
          sx={{ marginBottom: '16px' }}
        />
        <LoadingButton
          fullWidth
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{
            backgroundColor: '#092141',
            marginTop: '16px',
            padding: '12px',
          }}
        >
          Send OTP
        </LoadingButton>
      </form>
    </>
  ) : null;
}

function OtpForm({
  otp,
  handleOtpChange,
  mobileNumber,
  onVerify,
  onResendOtp,
  errorMessage,
  timer,
  canResend,
}) {
  const inputRefs = useRef([]);

  useEffect(() => {
    // Focus on the first empty input field when component mounts
    const firstEmptyIndex = otp.findIndex((digit) => digit === '');
    if (firstEmptyIndex !== -1) {
      inputRefs.current[firstEmptyIndex].focus();
    }
  }, [otp]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (!/^\d?$/.test(value)) return; // Only allow single digit

    handleOtpChange(value, index);

    // Move to the next input if a digit was entered
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleOtpVerification = () => {
    const otpString = otp.join('');
    onVerify(otpString);
  };

  return (
    <>
      <Typography
        variant='body1'
        sx={{ marginBottom: '16px', textAlign: 'center', color: '#343a3f' }}
        gutterBottom
      >
        Enter the OTP sent to
        <Typography variant='body1' color='primary'>
          <strong> +91 {mobileNumber}</strong>
        </Typography>
      </Typography>
      <Box display='flex' justifyContent='center' mb={2}>
        {otp.map((digit, index) => (
          <TextField
            key={index}
            variant='outlined'
            margin='normal'
            sx={{
              width: '50px',
              marginRight: '8px',
              textAlign: 'center',
            }}
            value={digit}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            inputProps={{
              maxLength: 1,
              ref: (el) => (inputRefs.current[index] = el),
            }}
          />
        ))}
      </Box>
      {errorMessage && (
        <Typography
          variant='body2'
          sx={{ marginBottom: '16px', textAlign: 'center', color: 'red' }}
        >
          {errorMessage}
        </Typography>
      )}
      {!canResend ? (
        <Typography variant='body2' sx={{ marginBottom: '16px', textAlign: 'center' }} gutterBottom>
          Resend OTP in: <strong>{timer} seconds</strong>
        </Typography>
      ) : (
        <Typography
          variant='body2'
          sx={{ marginBottom: '16px', textAlign: 'center', color: 'blue', cursor: 'pointer' }}
          gutterBottom
          onClick={onResendOtp}
        >
          Resend OTP
        </Typography>
      )}
      <Button
        variant='contained'
        color='primary'
        fullWidth
        sx={{
          backgroundColor: '#092141',
          marginTop: '16px',
          padding: '12px',
        }}
        onClick={handleOtpVerification}
      >
        Login
      </Button>
    </>
  );
}

function MobileLogin() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState(['', '', '', '']);
  const [mobileNumber, setMobileNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const alert = useSelector((x) => x.alert.value);
  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);
  const intervalRef = useRef(null);
  const timerRef = useRef(30);

  const startTimer = () => {
    setTimer(30);
    setCanResend(false);
    timerRef.current = 30;
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      if (timerRef.current <= 1) {
        clearInterval(intervalRef.current);
        setCanResend(true);
        setTimer(0);
      } else {
        timerRef.current -= 1;
        setTimer(timerRef.current);
      }
    }, 1000);
  };

  const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      backgroundColor: '#eaeef2',
      height: '92vh',
      overflow: 'hidden',
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
    },
  }));

  const StyledSection = styled('div')(({ theme }) => ({
    width: '50%',
    height: '92vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      marginTop: '7vh',
      width: '100%',
      height: '100%',
    },
  }));

  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '60%',
      minHeight: '20vh',
    },
  }));

  const handleSubmit = async (data) => {
    setMobileNumber(data.mobile); // Set the mobile number for OTP
    try {
      const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
      // POST request to send OTP
      // const result = await fetchWrapper.post(`${baseUrl}/sendOtp`, { phoneNumber: data.mobile });
      // console.log('OTP sent successfully:', result.message);

      const emailResult = await fetchWrapper.post(`${baseUrl}/sendEmailOtp`, {
        phoneNumber: data.mobile,
      });
      console.log('OTP sent via Email:', emailResult.message);
      handleNext(); // Move to the next step
      startTimer();
    } catch (error) {
      console.error('Error sending OTP:', error);
      // Optionally, display error to the user
    }
  };

  const handleNext = () => {
    setStep(2);
  };

  const handleSwitch = () => {
    navigate('/payment');
  };

  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const handleVerifyOtp = async (otpString) => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
      const result = await fetchWrapper.post(`${baseUrl}/verifyOtp`, {
        phoneNumber: mobileNumber,
        otp: otpString,
      });

      if (result.message === 'OTP verified successfully') {
        console.log('OTP verified successfully:', result.message);
        handleSwitch();
      } else if (result.message === 'Invalid OTP') {
        setErrorMessage('Invalid OTP. Please try again.'); // Set error message
      } else {
        setErrorMessage('Unexpected response from server.'); // Handle unexpected messages
      }
    } catch (error) {
      setErrorMessage('Invalid OTP. Please try again.'); // Set error message
      console.error('Error verifying OTP:', error);
    }
  };

  const handleResendOtp = async () => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
      // const result = await fetchWrapper.post(`${baseUrl}/sendOtp`, { phoneNumber: mobileNumber });
      // console.log('OTP resent successfully:', result.message);

      const result = await fetchWrapper.post(`${baseUrl}/sendEmailOtp`, {
        phoneNumber: mobileNumber,
      });
      console.log('OTP resent on Email  successfully:', result.message);

      setOtp(['', '', '', '']);
      startTimer();
      setErrorMessage();
    } catch (error) {
      console.error('Error resending OTP:', error);
    }
  };

  return (
    <StyledRoot>
      <StyledSection>
        <img
          src={Logo}
          alt='login'
          onError={(e) => {
            console.warn('Error loading image:', e);
            e.target.style.display = 'none';
          }}
        />
      </StyledSection>
      <Container
        maxWidth='sm'
        sx={{
          backgroundColor: '#eaeef2',
          height: 'auto',
          '@media (max-width: 600px)': {
            height: '420px',
          },
        }}
      >
        <StyledContent>
          <div className={`alert ${alert && alert.type}`}>{alert && alert.message}</div>
          <Typography
            variant='h4'
            sx={{
              marginBottom: '16px',
              textAlign: 'center',
            }}
            gutterBottom
          >
            Login
          </Typography>
          {step === 1 && <MobileLoginForm onSubmit={handleSubmit} step={step} />}
          {step === 2 && (
            <OtpForm
              otp={otp}
              handleOtpChange={handleOtpChange}
              mobileNumber={mobileNumber}
              onVerify={handleVerifyOtp}
              onResendOtp={handleResendOtp}
              errorMessage={errorMessage}
              startTimer={startTimer}
              timer={timer}
              canResend={canResend}
              // Pass error message as a prop
            />
          )}
        </StyledContent>
      </Container>
    </StyledRoot>
  );
}

import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import {
  useMediaQuery,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Box,
  Button,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from '_store';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import InputBase from '@mui/material/InputBase';
import Logo from '_assets/images/logo.gif';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '40vw',
      '&:focus': {
        width: '50vw',
      },
    },
  },
}));

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

function Nav({ scrollToSection }) {
  const dispatch = useDispatch();
  const location = useLocation(); // Get current URL location
  const [isLoading] = useState(false);
  let user = JSON.parse(localStorage.getItem('auth')) || [];
  const [anchorEl, setAnchorEl] = useState(null);
  const [query, setQuery] = useState('');
  const [activeItem, setActiveItem] = useState('Home'); // State for active navbar item

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // State to control visibility of navbar items in mobile view
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control menu icon toggle
  const isMobileView = useMediaQuery('(max-width:768px)'); // Adjust the breakpoint as per your requirement

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu icon
    setMobileMenuOpen(!mobileMenuOpen); // Toggle visibility of navbar items in mobile view
  };

  const navigate = useNavigate();

  // Update activeItem based on current route
  useEffect(() => {
    switch (location.pathname) {
      case '/home':
        setActiveItem('Home');
        break;
      case '/product':
        setActiveItem('Buy A Course');
        break;
      // case '/account/signUp':
      //   setActiveItem('Account');
      //   break;
      case '/account/login':
        setActiveItem('Account');
        break;
      default:
        setActiveItem('');
    }
  }, [location.pathname]); // This will update activeItem when the URL changes

  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(authActions.logout());
    handleClose(); // Close the menu after logout
  };
  const handleDashboard = () => {
    navigate('/Dashboard/?login_successful=true');
  };

  const handleLogin = () => {
    navigate('/account/signUp');
  };
  // const handleSignUp = () => {
  //   navigate('/account/signUp');
  // };
  const handleProduct = () => {
    navigate('/product');
  };
  useEffect(() => {
    if (query) {
      navigate(`/search/${query}`);
    }
  }, [query]);

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  const handleNavClick = (section) => {
    setActiveItem(section); // Set active state
    if (scrollToSection) {
      scrollToSection(section);
    } else {
      navigate('/home');
    }
  };

  const navItemStyle = (item) => ({
    color: activeItem === item ? '#fe6f61' : '#ffffff',
    borderBottom: activeItem === item ? '2px solid #fe6f61' : 'none',
  });

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position='fixed' sx={{ backgroundColor: '#092141', paddingRight: '0 !important' }}>
        <Toolbar sx={{ height: '8vh' }}>
          {isMobileView && (
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label={isMenuOpen ? 'close menu' : 'menu'}
              sx={{ mr: 2 }}
              onClick={handleMenuClick}
              style={{ display: user && user.token ? 'none' : 'block' }}
            >
              {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          )}
          <div className='d-flex justify-content-between w-100'>
            <Link to='/' className='text-decoration-none text-light d-flex align-items-center '>
              <img
                src={Logo}
                alt='Logo'
                height='40px'
                className='px-3 classroom-icon'
                onError={(e) => {
                  console.warn('Error loading image:', e);
                  e.target.style.display = 'none';
                }}
              />
              <Typography variant='h6' noWrap component='div'>
                Classroom-ai
              </Typography>
            </Link>
            {user && user.token ? (
              <div className='d-flex align-items-center'>
                {user.role === 'student' || user.role === 'teacher' ? (
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder='Search…'
                      inputProps={{ 'aria-label': 'search' }}
                      onChange={handleSearch}
                    />
                  </Search>
                ) : (
                  ''
                )}
                <Tooltip title='User' arrow>
                  <IconButton
                    onClick={handleUserClick}
                    color='inherit'
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <AccountCircleIcon />
                    <Typography color='inherit' className='text-capitalize ms-2'>
                      {user.first_name}
                    </Typography>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '30px' }}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleDashboard}>My Dashboard</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            ) : (
              <>
                {isMobileView ? (
                  <div
                    className='text-decoration-none text-light d-flex align-items-start flex-column'
                    style={{
                      backgroundColor: '#092141',
                      padding: '0px',
                      position: 'absolute',
                      left: 0,
                      top: '8vh',
                      width: '100%',
                    }}
                  >
                    {(isMobileView && mobileMenuOpen) || !isMobileView ? (
                      <div className='d-flex align-items-start flex-column'>
                        <Button
                          className='text-light text-capitalize'
                          aria-haspopup='true'
                          onClick={() => handleNavClick('Home')}
                          style={navItemStyle('Home')}
                        >
                          <Typography variant='h6' noWrap component='div'>
                            Home
                          </Typography>
                        </Button>
                        <Button
                          className='text-capitalize'
                          aria-haspopup='true'
                          onClick={() => handleNavClick('AI Tutor')}
                          style={navItemStyle('AI Tutor')}
                        >
                          <Typography variant='h6' noWrap component='div'>
                            AI Tutor
                          </Typography>
                        </Button>
                        <Button
                          className='text-capitalize'
                          aria-haspopup='true'
                          onClick={() => handleNavClick('Interactive Models')}
                          style={navItemStyle('Interactive Models')}
                        >
                          <Typography variant='h6' noWrap component='div'>
                            Interactive Model
                          </Typography>
                        </Button>
                        <Button
                          className='text-capitalize'
                          aria-haspopup='true'
                          onClick={() => handleNavClick('Learning Programs')}
                          style={navItemStyle('Learning Programs')}
                        >
                          <Typography variant='h6' noWrap component='div'>
                            Learning Programs
                          </Typography>
                        </Button>
                        <Button
                          className='text-capitalize'
                          id='basic-button'
                          aria-controls={anchorEl ? 'Nav' : undefined}
                          aria-haspopup='true'
                          aria-expanded={Boolean(anchorEl)}
                          onClick={handleProduct}
                          style={navItemStyle('Buy A Course')}
                        >
                          <Typography variant='h6' noWrap component='div'>
                            Buy a Course
                          </Typography>
                        </Button>
                        {/* <Button
                          className='text-capitalize'
                          aria-haspopup='true'
                          onClick={handleSignUp}
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                          style={navItemStyle('Account')}
                        >
                          <Typography variant='h6' noWrap component='div'>
                            Account
                          </Typography>
                        </Button> */}
                        <Button
                          className='text-capitalize'
                          aria-haspopup='true'
                          onClick={handleLogin}
                          style={navItemStyle('Login')}
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                          <Typography variant='h6' noWrap component='div'>
                            Login
                          </Typography>
                        </Button>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <>
                    <div className='text-decoration-none text-light d-flex align-items-center '>
                      {(isMobileView && mobileMenuOpen) || !isMobileView ? (
                        <div className='d-flex align-items-center'>
                          <Button
                            className='text-capitalize'
                            aria-haspopup='true'
                            onClick={() => handleNavClick('Home')}
                            style={navItemStyle('Home')}
                          >
                            <Typography variant='h6' noWrap component='div'>
                              Home
                            </Typography>
                          </Button>
                          <Button
                            className='text-capitalize'
                            aria-haspopup='true'
                            onClick={() => handleNavClick('AI Tutor')}
                            style={navItemStyle('AI Tutor')}
                          >
                            <Typography variant='h6' noWrap component='div'>
                              AI Tutor
                            </Typography>
                          </Button>
                          <Button
                            className='text-capitalize'
                            aria-haspopup='true'
                            onClick={() => handleNavClick('Interactive Models')}
                            style={navItemStyle('Interactive Models')}
                          >
                            <Typography variant='h6' noWrap component='div'>
                              Interactive Model
                            </Typography>
                          </Button>
                          <Button
                            className='text-capitalize'
                            aria-haspopup='true'
                            onClick={() => handleNavClick('Learning Programs')}
                            style={navItemStyle('Learning Programs')}
                          >
                            <Typography variant='h6' noWrap component='div'>
                              Learning Programs
                            </Typography>
                          </Button>
                        </div>
                      ) : null}
                      <Button
                        className='text-capitalize'
                        id='basic-button'
                        aria-controls={anchorEl ? 'Nav' : undefined}
                        aria-haspopup='true'
                        aria-expanded={Boolean(anchorEl)}
                        onClick={handleProduct}
                        style={navItemStyle('Buy A Course')}
                      >
                        <Typography variant='h6' noWrap component='div'>
                          Buy a Course
                        </Typography>
                      </Button>
                      {/* <Button
                        className='text-capitalize'
                        aria-haspopup='true'
                        onClick={handleSignUp}
                        style={navItemStyle('Account')}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Typography variant='h6' noWrap component='div'>
                          Account
                        </Typography>
                      </Button> */}
                      <Button
                        className='text-capitalize'
                        aria-haspopup='true'
                        onClick={handleLogin}
                        style={navItemStyle('Login')}
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Typography variant='h6' noWrap component='div'>
                          Login
                        </Typography>
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {isLoading ? <div>Loading</div> : null}
    </Box>
  );
}

Nav.propTypes = {
  children: PropTypes.string,
};

export { Nav };

import React, { createContext, useContext, useState } from 'react';

// Create a context for payment data
const PaymentContext = createContext();

export const usePayment = () => {
  return useContext(PaymentContext);
};

export const PaymentProvider = ({ children }) => {
  const [paymentData, setPaymentData] = useState({
    title: '',
    price: '',
    subtitle: '',
    plan_value: '',
    planInterval: '',
    planInterval1: '',
    planInterval2: '',
    board: '',
    discount: '',
    country: '',
    state: '',
  });

  return (
    <PaymentContext.Provider value={{ paymentData, setPaymentData }}>
      {children}
    </PaymentContext.Provider>
  );
};

import React from 'react';
import { Typography, Box, Link } from '@mui/material';

const commonStyles = {
  body: {
    marginBottom: 3,
    fontSize: '1.2rem',
    color: 'text.primary',
  },
  heading: {
    marginTop: 2,
    marginBottom: 2,
    fontWeight: 'bold',
    color: '#092141',
    textTransform: 'uppercase',
  },
  title: {
    color: '#092141',
    marginBottom: 4,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
};

const PrivacyPolicy = () => {
  return (
    <Box sx={{ padding: '20px', margin: '1rem 6rem' }}>
      <Typography variant='h4' sx={commonStyles.title}>
        Privacy Policy
      </Typography>

      <Typography variant='h5' sx={commonStyles.heading}>
        1. Introduction
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        Welcome to{' '}
        <Link
          href='/'
          underline='hover'
          style={{
            color: '#fe5f4f',
            fontWeight: 'bold',
          }}
        >
          {' '}
          www.classroom-ai.com
        </Link>{' '}
        (“we,” “our,” “us”). We are committed to protecting your privacy and ensuring that your
        personal information is handled in a safe and responsible manner. This Privacy Policy
        outlines how we collect, use, and protect your information when you visit our website{' '}
        <Link
          href='/'
          underline='hover'
          style={{
            color: '#fe5f4f',
            fontWeight: 'bold',
          }}
        >
          {' '}
          www.classroom-ai.com
        </Link>{' '}
        (the “Site”).
      </Typography>
      <Typography variant='h5' sx={commonStyles.heading}>
        2. Information We Collect
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        We may collect the following types of information:
      </Typography>
      <ul style={{ marginLeft: '20px', marginBottom: 3, fontSize: '1.2rem' }}>
        <li>
          <strong>Personal Information:</strong> Name, email address, phone number, and other
          contact details.
        </li>
        <li>
          <strong>Usage Data:</strong> Information about how you use our Site, such as your IP
          address, browser type, pages visited, and the time and date of your visit.
        </li>
        <li>
          <strong>Cookies and Tracking Technologies:</strong> We use cookies and similar
          technologies to track your activity on our Site and hold certain information.
        </li>
      </ul>

      <Typography variant='h5' sx={commonStyles.heading}>
        3. How We Use Your Information
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        We use the information we collect for various purposes, including:
      </Typography>
      <ul style={{ marginLeft: '20px', marginBottom: 3, fontSize: '1.2rem' }}>
        <li>To provide and maintain our Site</li>
        <li>To notify you about changes to our Site</li>
        <li>To allow you to participate in interactive features of our Site</li>
        <li>To provide customer support</li>
        <li>To gather analysis or valuable information so that we can improve our Site</li>
        <li>To monitor the usage of our Site</li>
        <li>To detect, prevent, and address technical issues</li>
      </ul>

      <Typography variant='h5' sx={commonStyles.heading}>
        4. Sharing Your Information
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        We do not sell, trade, or otherwise transfer your personal information to outside parties
        except as described in this Privacy Policy. We may share your information with:
      </Typography>
      <ul style={{ marginLeft: '20px', marginBottom: 3, fontSize: '1.2rem' }}>
        <li>
          <strong>Service Providers:</strong> Third-party companies and individuals that provide
          services on our behalf.
        </li>
        <li>
          <strong>Legal Requirements:</strong> If required by law or in response to valid requests
          by public authorities.
        </li>
      </ul>

      <Typography variant='h5' sx={commonStyles.heading}>
        5. Security of Your Information
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        We take reasonable measures to protect your personal information from unauthorized access,
        use, or disclosure. However, no method of transmission over the internet or method of
        electronic storage is 100% secure.
      </Typography>

      <Typography variant='h5' sx={commonStyles.heading}>
        6. Your Data Protection Rights
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        Depending on your location, you may have the following rights regarding your personal
        information:
      </Typography>
      <ul style={{ marginLeft: '20px', marginBottom: 3, fontSize: '1.2rem' }}>
        <li>
          <strong>The right to access:</strong> You have the right to request copies of your
          personal data.
        </li>
        <li>
          <strong>The right to rectification:</strong> You have the right to request that we correct
          any information you believe is inaccurate.
        </li>
        <li>
          <strong>The right to erasure:</strong> You have the right to request that we erase your
          personal data, under certain conditions.
        </li>
        <li>
          <strong>The right to restrict processing:</strong> You have the right to request that we
          restrict the processing of your personal data, under certain conditions.
        </li>
        <li>
          <strong>The right to object to processing:</strong> You have the right to object to our
          processing of your personal data, under certain conditions.
        </li>
        <li>
          <strong>The right to data portability:</strong> You have the right to request that we
          transfer the data that we have collected to another organization, or directly to you,
          under certain conditions.
        </li>
      </ul>

      <Typography variant='h5' sx={commonStyles.heading}>
        7. Changes to This Privacy Policy
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        We may update our Privacy Policy from time to time. We will notify you of any changes by
        posting the new Privacy Policy on this page.
      </Typography>

      <Typography variant='h5' sx={commonStyles.heading}>
        8. Contact Us
      </Typography>
      <Typography variant='body1' sx={commonStyles.body}>
        If you have any questions about this Privacy Policy, please contact us at:
        <strong
          style={{
            color: '#fe5f4f',
            fontWeight: 'bold',
          }}
        >
          {' '}
          info@newgenedu.com
        </strong>
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;

import React from 'react';
import { Grid, Typography, Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import Logo from '_assets/images/logo.gif';
import Icon from '@mui/material/Icon';
import vectorOverlay from '_assets/images/spiderImage.svg';

// Styled components
const FooterContainer = styled('footer')(() => ({
  backgroundColor: '#041731',
  color: '#fff',
  padding: '50px 90px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: "space-between",
  position: 'relative',
}));

const FooterSection = styled('div')(() => ({
  flex: 1,
}));

const VerticalLine = styled(Box)(() => ({
  width: '2px', // Adjust thickness
  backgroundColor: '#fff', // Line color
  height: '150px',
  marginTop: "40px"
}));

const OverlayImage = styled('img')(() => ({
  position: 'absolute',
  top: '-60px', // Adjust this value as necessary to position the image above the footer
  left: '-10%',
  transform: 'translateX(0%)',
  zIndex: 0, // Behind other content
  width: '100%', // Adjust width to fill the footer
  height: 'auto' // Keep the aspect ratio
}));

const Footer = () => {
  return (
    <FooterContainer>
      <OverlayImage
        src={vectorOverlay}
        alt="Vector Overlay"
      />
      <Box
        sx={{
          width: '100%',
          maxWidth: '1200px',
          margin: '0 auto',
          zIndex: 1, // Bring content above the overlay
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Grid container alignItems="flex-start" justifyContent="space-between" spacing={2}>
            <Grid item xs={12} md={5} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
              <FooterSection>
                <Typography variant="h4" sx={{ paddingBottom: '20px', }}>About us</Typography>
                <Typography variant="body2" className='text-start pt-4'>
                  We built hundreds of Interactive models to give life to
                </Typography>
                <Typography variant="body2" className='text-start pt-3'>
                  the figures and concepts of Math and Science Curriculum.
                </Typography>
                <Typography variant="body2" className='text-start pt-3'>
                  of CBSE, State Boards and SAT.
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                  <Link sx={{ fontSize: "15px", marginBottom: "10px" }} href="/privacy-policy" color="inherit" underline='hover' >
                    Privacy  Policy
                  </Link>
                </Typography>

              </FooterSection>
            </Grid>

            {/* Vertical Line */}
            <Grid item sx={{ display: { xs: 'none', md: 'block' } }}> {/* Hide on small screens */}
              <VerticalLine />
            </Grid>

            <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
              <FooterSection>
                <Typography variant="h4" sx={{ paddingBottom: '30px' }}>Follow/Contact us</Typography>
                <ul className='mt-4 list-unstyled'>
                  <li className='mb-2 text-white'>
                    <div className='d-flex justify-content-center justify-content-md-end'>
                      <div>Tirunelveli, Tamil Nadu 627114</div>
                      <Icon sx={{ color: 'red', marginRight: '8px' }}>placeholder</Icon>
                    </div>
                  </li>
                  <li className='mb-2 text-white'>
                    <div className='d-flex justify-content-center justify-content-md-end'>
                      <div>+91 8148493200</div>
                      <Icon sx={{ color: 'red', marginRight: '8px' }}>call</Icon>
                    </div>
                  </li>
                  <li className='mb-2 text-white'>
                    <div className='d-flex justify-content-center justify-content-md-end'>
                      <div>info@newgenedu.com</div>
                      <Icon sx={{ color: 'red', marginRight: '8px' }}>mail</Icon>
                    </div>
                  </li>
                </ul>
              </FooterSection>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <div className='container-fluid px-md-4 mx-4 text-body-secondary'>
        <div className='row'>
          <a
            className='d-inline-flex align-items-center mb-2 text-body-emphasis text-decoration-none'
            href='/'
            aria-label='Bootstrap'
          >
            <img
              src={Logo}
              alt='login'
              style={{ height: '100px', marginRight: '10px' }}
              onError={(e) => {
                console.warn('Error loading image:', e);
                e.target.style.display = 'none';
              }}
            />
            <span className='fs-5 text-white'>Classroom-ai</span>
          </a>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;

import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Paper,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Chip,
  Button,
} from '@mui/material';
import { Loader } from '_components/navigation/Loader';
import { MarksCalculator } from '_components/common';
import { fetchWrapper } from '_helpers';
import { useSelector } from 'react-redux';
import QuizWrapper from '_components/chapters/QuizWrapper';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

const OpenAIQuestionGenerator = (props) => {
  const user = useSelector((x) => x.auth.value);
  const {
    topic,
    board,
    setHideChatbot,
    quizDetails,
    askGPT,
    setChatbotOpen,
    setChat,
    subject,
    chapterTitle,
    subSectionTitle,
    standard,
    chapterId,
    subsectionId,
    sectionId,
    schoolId,
    sectionTitle,
  } = props;
  let splitTopic = topic.split(':')[1];
  let newTopic = splitTopic && splitTopic.toLowerCase();
  const [questions, setQuestions] = useState([]);
  const [options, setOptions] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [showExplanation, setShowExplanation] = useState(false);
  const [explanation, setExplanation] = useState('');
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [results, setResults] = useState({});
  const [answeredCount, setAnsweredCount] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [wrongCount, setWrongCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [level, setLevel] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [questionsList, setQuestionsList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [showNextButton, setShowNextButton] = useState(false);
  const [showMarksCalculator, setShowMarksCalculator] = useState(false);
  const [data, setData] = useState('');

  console.log('res', results);
  useEffect(() => {
    setHideChatbot(true);
  }, []);
  const aiPerformanceAnalysis = async () => {
    const quizNames = quizDetails && quizDetails.length > 0 ? quizDetails[0].quiz_name : '';
    const quizTitles = quizDetails && quizDetails.length > 0 ? quizDetails[0].quiz_title : '';
    const totalQuestions = questionsList.length;
    const wrongAnswers = wrongCount;
    const rightAnswers = answeredCount - wrongAnswers;
    const totalMarks = totalQuestions * (board === 'NEET' ? 4 : 1);
    const obtainedMarks = rightAnswers * (board === 'NEET' ? 4 : 1);
    const percentScore = (obtainedMarks / totalMarks) * 100;
    const requestBody = {
      totalQuestion: totalQuestions,
      wrongAnswers: wrongAnswers,
      rightAnswers: rightAnswers,
      obtainedMarks: obtainedMarks,
      totalMarks: totalMarks,
      obtainedScore: percentScore,
      percentScore: percentScore,
      level: level,
      board: board,
      subject: subject,
      chapter: chapterTitle,
      subsection: subSectionTitle,
      user_id: user.id,
      school: schoolId,
      email: user.email,
      class: standard,
      section: sectionTitle,
      chapter_id: chapterId,
      sub_section_id: subsectionId,
      section_id: sectionId,
      quiz_name: quizNames,
      quiz_title: quizTitles,
    };
    try {
      const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
      const response = await fetchWrapper.post(`${baseUrl}/ai_performance_analysis`, requestBody);
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  const generateQuestions = async (difficultyLevel) => {
    try {
      setIsLoading(true);
      const lastThreeQuestions = chatHistory.slice(-5).join(', ');
      const prompt =
        board === 'NEET' && difficultyLevel !== 'real-world'
          ? `${
              lastThreeQuestions ? `DONT INCLUDE ${lastThreeQuestions} OR SIMILAR QUESTIONS.` : ''
            } Generate with difficulty level as very ${difficultyLevel} multiple-choice questions (MCQs) with answer about given topic. Topic: ${newTopic.trim()}. for NEET.`
          : difficultyLevel == 'real-world'
          ? `Generate a unique and engaging real-world application question on the topic ${newTopic.trim()} The question should require students to write a  ${newTopic.trim()} based on a scenario from daily life, industry, or laboratory settings. Avoid repetition with the last three questions.`
          : `DONT INCLUDE ${lastThreeQuestions} OR SIMILAR QUESTIONS.Generate with difficulty level as very ${difficultyLevel} multiple-choice questions (MCQs) about given topic. topic:${newTopic.trim()}.`;
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          messages: [
            {
              role: 'system',
              //   content: `You are NEET exam tutor. As a tutor, you should generate multiple choice questions , answers with as very ${difficultyLevel} like previous year papers and solution as shown below.
              //   Questions: The amount of energy required to form soup bubble of radius 2 cm from a soup solution is nearly (surface tension of soup solution =0.03 N m-1)
              //   1) 3.01 × 10–4 J
              //  2) 50.1 × 10–4 J
              //  3) 30.16 × 10–4 J
              //  4) 5.06 × 10–4 J
              // Solution : Answer (1)
              // Sol. Amount of energy required = [S × deltaA] × 2
              // => Energy required = [0.03 × 4 × pi × 4 × 10–4] × 2 = 3.015 × 10–4 J  in the following JSON format {"question": "","options": [],"answer": ""}`,

              content: `You are a quiz master. Generate practical question with four multiple-choice answers with level as very ${difficultyLevel} like previous year papers. The question should assess a student's ability to apply concepts from the topic in real-world or laboratory scenarios. Provide the question, options, and the correct answer separately in the following JSON format: {"question": "","options": [],"answer": ""}`,
            },
            {
              role: 'user',
              content: prompt,
            },
          ],
          model: 'gpt-4o-mini',
          n: 1,
          top_p: 1,
          frequency_penalty: 0.2,
          presence_penalty: 0.2,
          max_tokens: 150,
          temperature: 1,
          stop: ['\n\n'],
        }),
      });

      const data = await response.json();
      const responseData = data.choices[0].message.content;
      const jsonData = JSON.parse(responseData);
      setData(jsonData);
      if (questionsList.length < 20) {
        setQuestionsList((prevQue) => [...prevQue, jsonData]);
        setCurrentIndex((prevCount) => prevCount + 1);
      } else {
        setIsLoading(false);
        setShowMarksCalculator(true);
        aiPerformanceAnalysis();
        return;
      }

      setQuestions(jsonData.question);
      setOptions(jsonData.options);
      setAnswer(jsonData.answer);
      setChatHistory((prevHistory) => [...prevHistory, jsonData.question || '']);
      setSelectedAnswers({});
      setResults({});
      setIsLoading(false);
    } catch (error) {
      console.error('Error generating questions:', error);
      setIsLoading(false);
      generateQuestions(level);
    }
  };

  const handleAnswerChange = useCallback(
    async (question, selectedOption) => {
      setSelectedAnswers(selectedOption);
      setAnsweredCount((prevCount) => prevCount + 1);

      if (selectedOption == answer) {
        setQuestionsList((prevQuestionsList) => {
          const updatedQuestionsList = [...prevQuestionsList];
          const currentQuestion = updatedQuestionsList[currentIndex];
          updatedQuestionsList[currentIndex] = {
            ...currentQuestion,
            selectedOption: selectedOption,
          };
          return updatedQuestionsList;
        });
        setShowExplanation(false);
        setExplanation();
        setShowExplanation(false);
        setCorrectCount((prevCount) => prevCount + 1);
        setShowNextButton(true);
      } else {
        setShowNextButton(false);
        await checkAnswer(question, answer, selectedOption);
        setWrongCount((prevCount) => prevCount + 1);
        setShowExplanation(true);
      }
    },
    [questionsList, selectedAnswers],
  );

  const handleNextClick = () => {
    if (currentIndex < questionsList.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      const nextQuestion = questionsList[currentIndex + 1];
      setQuestions(nextQuestion.question);
      setOptions(nextQuestion.options);
      setAnswer(nextQuestion.answer);
      setSelectedAnswers(nextQuestion.selectedOption || {});
      setShowExplanation(!!nextQuestion.explanation);
      setExplanation(nextQuestion.explanation || '');
    } else {
      setExplanation();
      setShowExplanation(false);
      generateQuestions(level);
    }
  };

  const handlePreviousClick = () => {
    if (currentIndex > 0) {
      const previousIndex = currentIndex - 1;
      setCurrentIndex(previousIndex);
      const previousQuestion = questionsList[previousIndex];
      setQuestions(previousQuestion.question);
      setOptions(previousQuestion.options);
      setAnswer(previousQuestion.answer);
      setSelectedAnswers(previousQuestion.selectedOption || {});
      setShowExplanation(!!previousQuestion.explanation);
      setExplanation(previousQuestion.explanation || '');
    }
  };
  const checkAnswer = async (question, answer, selectedAnswer, retries = 3, delay = 1000) => {
    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          max_tokens: 300,
          temperature: 0.2,
          model: 'gpt-4o-mini',
          top_p: 0.02,
          frequency_penalty: 0,
          presence_penalty: 0,
          n: 1,
          stop: ['\n\n'],
          messages: [
            {
              role: 'system',
              content: `The answer for the question "${question}" is not "${selectedAnswer}" from options [${options}] but "${answer}". Provide an explanation for why "${answer}" is correct and "${selectedAnswer}" is incorrect. Output the explanation in a strict JSON format with a single key 'explanation' and a string value. For example: {"explanation": "Your explanation here."}. The explanation should be clear and concise, focusing on key points such as relevant concepts, equations, and comparisons.`,
            },
          ],
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.choices && data.choices.length > 0) {
          const explanationJson = data.choices[0].message.content;
          const explanation = JSON.parse(explanationJson);
          const formattedExplanation = explanation.explanation.replace(/\n/g, '<br />');
          setExplanation(formattedExplanation);
          setQuestionsList((prevQuestionsList) => {
            const updatedQuestionsList = [...prevQuestionsList];
            const currentQuestion = updatedQuestionsList[currentIndex];
            updatedQuestionsList[currentIndex] = {
              ...currentQuestion,
              selectedOption: selectedAnswer,
              explanation: formattedExplanation,
            };
            return updatedQuestionsList;
          });
          setShowNextButton(true);
        } else {
          console.error('Error: No choices returned from API');
          throw new Error('No choices returned from API');
        }
      } else {
        console.error(`Error: Response not OK, status code: ${response.status}`);
        throw new Error(`Response not OK, status code: ${response.status}`);
      }
    } catch (error) {
      console.error('Error checking answer:', error.message);

      if (retries > 0) {
        console.log(`Retrying in ${delay} ms...`);
        await wait(delay);
        await checkAnswer(question, answer, selectedAnswer, retries - 1, delay * 2); // Exponential backoff
      } else {
        console.error('Max retries reached. No more retries will be attempted.');
        setShowNextButton(true);
      }
    }
  };

  const handleStart = (val) => {
    setLevel(val);
    if (val !== 'custom-quiz') {
      generateQuestions(val);
    }
  };
  const quizWrapperProps = {
    ...props,
    board: board,
    totalQuestions: Number(questionsList.length),
    answeredQuestion: 0,
    wrongAnswers: 0,
    rightAnswers: 0,
    level,
    data,
    askGPT,
    setChatbotOpen,
    setChat,
  };

  const handleRestart = () => {
    setQuestions([]);
    setOptions([]);
    setAnswer([]);
    setShowExplanation(false);
    setExplanation('');
    setSelectedAnswers({});
    setResults({});
    setAnsweredCount(0);
    setCorrectCount(0);
    setWrongCount(0);
    setIsLoading(false);
    setLevel('');
    setChatHistory([]);
    setQuestionsList([]);
    setCurrentIndex(-1);
    setShowNextButton(false);
    setShowMarksCalculator(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className='d-flex justify-content-between text-center align-items-center'>
        <div className='h4 text-success'>{topic}</div>
        {questions && questions.length < 1 ? (
          ''
        ) : (
          <div className='d-block text-end'>
            <button className='pushable mb-2'>
              <span className='shadow'></span>
              <span
                className='edge'
                style={{
                  background:
                    'linear-gradient(to left, ' +
                    'rgba(0, 0, 0, 0.16) 0%, ' + // 16% black
                    'rgba(0, 0, 0, 0.32) 8%, ' + // 32% black
                    'rgba(0, 0, 0, 0.32) 92%, ' + // 32% black
                    'rgba(0, 0, 0, 0.16) 100%)', // 16% black
                }}
              ></span>

              <span className='front bg-dark text-light' onClick={handleRestart}>
                Restart
              </span>
            </button>
          </div>
        )}
      </div>
      {showMarksCalculator ? (
        <MarksCalculator
          board={board}
          totalQuestions={Number(questionsList.length)}
          answeredQuestion={answeredCount}
          wrongAnswers={wrongCount}
          rightAnswers={answeredCount - wrongCount}
          level={level}
          data={data}
        />
      ) : (
        <>
          {level && questions ? (
            <div className='d-flex justify-content-between'>
              {level !== 'custom-quiz' && (
                <>
                  <div>
                    <div disabled className=' btn btn-success border-rounded mx-2'>
                      Total Questions:{answeredCount}
                    </div>
                    <div disabled className=' btn btn-warning border-rounded mx-2'>
                      Correct:{correctCount}
                    </div>
                  </div>
                  <Chip label={`Level: ${level}`} variant='outlined' color='info' />
                </>
              )}
            </div>
          ) : (
            <div className='container'>
              <div>
                <div className='alert alert-danger'>Please select level</div>
              </div>
              <div className='row pt-2'>
                <div className='col-12 col-md-6 mb-3'>
                  <div
                    className='card btn text-white bg-success level-btn'
                    style={{ width: '100%' }}
                    onClick={() => handleStart('easy')}
                  >
                    <div className='card-body'>
                      <h5 className='card-title'>Easy</h5>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 mb-3'>
                  <div
                    className='card btn text-white bg-warning level-btn'
                    style={{ width: '100%' }}
                    onClick={() => handleStart('medium')}
                  >
                    <div className='card-body'>
                      <h5 className='card-title'>Medium</h5>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 mb-3'>
                  <div
                    className='card btn text-white bg-danger level-btn'
                    style={{ width: '100%' }}
                    onClick={() => handleStart('hard')}
                  >
                    <div className='card-body'>
                      <h5 className='card-title'>Hard</h5>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 mb-3'>
                  <div
                    className='card btn text-white bg-secondary level-btn'
                    style={{ width: '100%' }}
                    onClick={() => handleStart('real-world')}
                  >
                    <div className='card-body'>
                      <h5 className='card-title'>Real-World Challenge</h5>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 mb-3'>
                  <div
                    className='card btn text-white bg-primary level-btn'
                    style={{ width: '100%' }}
                    onClick={() => handleStart('custom-quiz')}
                  >
                    <div className='card-body'>
                      <h5 className='card-title'>Customized Hard quiz</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {questions &&
            level &&
            (level === 'custom-quiz' ? (
              <QuizWrapper data={quizWrapperProps} />
            ) : (
              <Box mt={2}>
                <Paper elevation={3} style={{ padding: '10px', margin: '20px 10px' }}>
                  <Typography variant='body1'>{questions}</Typography>
                  <RadioGroup
                    onChange={(event) => handleAnswerChange(questions, event.target.value)}
                  >
                    {options.map((option, index) => (
                      <div
                        key={option + index}
                        className={
                          selectedAnswers && option !== answer && option === selectedAnswers
                            ? 'd-flex align-items-center'
                            : ''
                        }
                      >
                        <FormControlLabel
                          value={option}
                          control={<Radio />}
                          label={`(${String.fromCharCode(49 + index)}). ${option}`}
                          disabled={selectedAnswers && selectedAnswers.length && option !== answer}
                          style={{
                            pointerEvents:
                              selectedAnswers && selectedAnswers.length && option === answer
                                ? 'none'
                                : 'all',
                          }}
                        />
                        {selectedAnswers && option !== answer && option === selectedAnswers && (
                          <div className='d-inline'>❌</div>
                        )}
                        {selectedAnswers && selectedAnswers.length && option === answer && (
                          <div className='d-inline'>✔️</div>
                        )}
                      </div>
                    ))}
                  </RadioGroup>
                  {showExplanation && explanation && (
                    <>
                      <Typography variant='body1'>
                        Correct:{' '}
                        {`(${String.fromCharCode(49 + options.indexOf(answer))}). ${answer}`}
                      </Typography>
                      <Typography variant='body1' style={{ color: 'red' }}>
                        Explanation:
                      </Typography>
                      <div>
                        {/* <MathJaxContext version={3}>
                          <Typography className='fw-bold badge bg-success text-wrap'>
                            <MathJax>
                              <div dangerouslySetInnerHTML={{ __html: explanation }}></div>
                            </MathJax>
                          </Typography>
                        </MathJaxContext> */}
                        <MathJaxContext version={3}>
                          <div style={{ backgroundColor: '#34ebb4', padding: '10px' }}>
                            <MathJax>
                              <div dangerouslySetInnerHTML={{ __html: explanation }} />
                            </MathJax>
                          </div>
                        </MathJaxContext>
                      </div>
                    </>
                  )}
                </Paper>
              </Box>
            ))}

          {questions && level && (
            <div className='d-flex justify-content-between'>
              {questionsList && questionsList.length && currentIndex > 0 ? (
                <Button variant='contained' onClick={handlePreviousClick}>
                  previous
                </Button>
              ) : (
                <div></div>
              )}
              {selectedAnswers.length && showNextButton && (
                <Button variant='contained' onClick={handleNextClick}>
                  Next
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default React.memo(OpenAIQuestionGenerator);

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './_store';
import { App } from './App';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { PaymentProvider } from '../src/account/individual/Product/PaymentContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <GoogleOAuthProvider clientId='794622105265-nqvf1cpbcgqfcipfl4aqo4s36iaguer3.apps.googleusercontent.com'>
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <HelmetProvider>
            <PaymentProvider>
              <App />
            </PaymentProvider>
          </HelmetProvider>
          <ToastContainer autoClose={3000} position='top-right' />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
);

import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Grid, MenuItem, Select, FormControl } from '@mui/material';
import ProductCardBG from '../../../_assets/images/productCardBg.jpg';
import ProductCardBGHover from '../../../_assets/images/productCardBgHover.png';

export default function ProductCard({ onCategorySelect, selectedCategory }) {
  const [cbseSelected, setCbseSelected] = React.useState('');

  const categories = [
    {
      id: 'CBSE',
      label: 'CBSE',
      subject: '',
      subCategories: [
        { id: 'CLASS_10', label: 'CLASS 10', subject: 'SCIENCE & MATH' },
        { id: 'CLASS_9', label: 'CLASS 9', subject: 'SCIENCE & MATH' },
        { id: 'CLASS_8', label: 'CLASS 8', subject: 'SCIENCE & MATH' },
      ],
    },
    { id: 'NEET_PREPARATION', label: 'NEET', subject: 'PREPARATION' },

    { id: 'SAT', label: 'SAT', subject: '' },
    { id: 'MATRIC', label: 'MATRIC', subject: '' },
  ];

  // Handle CBSE dropdown selection
  const handleCbseChange = (event) => {
    setCbseSelected(event.target.value);
    onCategorySelect(event.target.value); // Call the function to pass the selected category up
  };

  return (
    <Box
      sx={{
        margin: {
          xs: '25px', // 80% width on mobile screens
          md: '50px', // 95% width on desktop screens
        },
        width: {
          xs: '85%', // 80% width on mobile screens
          md: '95%', // 95% width on desktop screens
        },
      }}
    >
      <Grid
        container
        spacing={{
          xs: 0, // 5 spacing on mobile screens
          md: 20, // 20 spacing on desktop screens
        }}
        justifyContent='center'
      >
        {categories.map((category) => (
          <Grid item xs={9} sm={6} md={3} key={category.id}>
            {category.id === 'CBSE' ? (
              // Dropdown for CBSE with subcategories
              <Card
                sx={{
                  borderRadius: '16px',
                  backgroundImage:
                    selectedCategory === category.id
                      ? `url(${ProductCardBGHover})`
                      : `url(${ProductCardBG})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  color: selectedCategory === category.id ? '#fe6f61' : '#808080',
                  border: '1px solid #fff',
                  cursor: 'pointer',
                  transform: selectedCategory === category.id ? 'scale(1.05)' : 'none',
                  transition: 'transform 0.3s',
                  width: '100%',
                  height: '75%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography sx={{ fontSize: { xs: 14, md: 16 } }}>{category.label}</Typography>
                  <FormControl sx={{ mt: 2, minWidth: 120 }}>
                    <Select
                      value={cbseSelected}
                      onChange={handleCbseChange}
                      displayEmpty
                      sx={{
                        backgroundColor: '#fff',
                        borderRadius: '4px',
                      }}
                    >
                      <MenuItem value='' disabled>
                        Select Class
                      </MenuItem>
                      {category.subCategories.map((subCategory) => (
                        <MenuItem key={subCategory.id} value={subCategory.id}>
                          {subCategory.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
            ) : (
              // Render regular category cards
              <Card
                sx={{
                  borderRadius: '16px',
                  backgroundImage:
                    selectedCategory === category.id
                      ? `url(${ProductCardBGHover})`
                      : `url(${ProductCardBG})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  color: selectedCategory === category.id ? '#fe6f61' : '#808080',
                  border: '1px solid #fff',
                  cursor: 'pointer',
                  transform: selectedCategory === category.id ? 'scale(1.05)' : 'none',
                  transition: 'transform 0.3s',
                  width: '100%',
                  height: '75%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    backgroundImage: `url(${ProductCardBGHover})`,
                  },
                }}
                onClick={() => onCategorySelect(category.id)}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  <Typography sx={{ fontSize: { xs: 14, md: 16 } }}>{category.label}</Typography>
                  <Typography sx={{ fontSize: { xs: 14, md: 16 } }}>{category.subject}</Typography>
                </CardContent>
              </Card>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

import React, { useState } from 'react';
import { Box, Button, Container, Grid } from '@mui/material';
import { styled } from '@mui/system';
import HowToUseAIGuideNEET from '../../../_assets/images/howToUseAIGuideNEET.mp4';
import HowToUseAIGuideNCERT from '../../../_assets/images/howToUseAIGuideNCERT.mp4';

const StyledButton = styled(Button)(({ selected, side }) => ({
  textTransform: 'none',

  padding: '10px 30px',
  borderRadius: side === 'left' ? '10px 0 0 10px' : side === 'right' ? '0 10px 10px 0' : '0',
  border: '1px solid #ffffff',
  color: selected ? '#fe6f61' : '#808080',
  backgroundColor: selected ? '#ffffff33' : 'transparent',
  '&:hover': {
    backgroundColor: selected ? '#ffffff33' : '#ffffff10',
  },
  transition: 'background-color 0.3s ease',
}));

export const ProductAIGuide = () => {
  const [selectedTab, setSelectedTab] = useState('neet');

  return (
    <Box
      sx={{
        minHeight: '100vh',
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
      }}
    >
      <Box
        variant='contained'
        sx={{
          backgroundColor: '#fe6f61',
          borderRadius: '20px',
          fontSize: '20px',
          padding: '10px 20px',
          '&:hover': {
            backgroundColor: '#FF1F46',
          },
        }}
      >
        HOW TO USE AI GUIDE?
      </Box>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 3,
          borderRadius: '25px',
          padding: '5px',
        }}
      >
        <StyledButton
          sx={{
            fontSize: { xs: '16px', md: '20px' },
          }}
          selected={selectedTab === 'neet'}
          onClick={() => setSelectedTab('neet')}
          side='left'
        >
          NEET
        </StyledButton>
        <StyledButton
          sx={{
            fontSize: { xs: '16px', md: '20px' },
          }}
          selected={selectedTab === 'ncert'}
          onClick={() => setSelectedTab('ncert')}
          side='right'
        >
          NCERT
        </StyledButton>
      </Container>
      {selectedTab === 'neet' && (
        <Grid container spacing={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 5,
              width: '100%',
            }}
          >
            <Grid item xs={12} sm={9} md={9} lg={11}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <video width='100%' height='100%' controls muted autoPlay>
                  <source src={HowToUseAIGuideNEET} type='video/mp4' />
                </video>
              </Box>
            </Grid>
          </Box>
        </Grid>
      )}
      {selectedTab === 'ncert' && (
        <Grid container spacing={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 5,
              width: '100%',
            }}
          >
            <Grid item xs={12} sm={9} md={9} lg={11}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <video width='100%' height='100%' controls muted autoPlay>
                  <source src={HowToUseAIGuideNCERT} type='video/mp4' />
                </video>
              </Box>
            </Grid>
          </Box>
        </Grid>
      )}
    </Box>
  );
};

import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import PayBG from '../../../_assets/images/payBg.jpg';
import ProductCardBG from '../../../_assets/images/productCardBg.jpg';
import { useNavigate } from 'react-router-dom';
import { usePayment } from './PaymentContext';
import { fetchWrapper } from '_helpers';

export const ProductPayment = ({ selectedCategory }) => {
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const [paymentDetails, setPaymentDetails] = useState({});
  const navigate = useNavigate();
  const [selectedInterval, setSelectedInterval] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        let res = await fetchWrapper.get(`${baseUrl}/getPlanCourse`);
        setPaymentDetails(res.paymentDetails);
      } catch (error) {
        console.error('Error fetching payment details:', error);
      }
    }

    if (Object.keys(paymentDetails).length === 0) {
      fetchData();
    }
  }, [baseUrl, paymentDetails]);

  const details = paymentDetails[selectedCategory] || {};

  const { setPaymentData } = usePayment(); // Use context to set payment data
  const handleSelect = (interval) => {
    setSelectedInterval(interval);
  };

  const handleBookClick = () => {
    setPaymentData({
      title: details.title,
      plan_value: details.plan_value,
      price: calculatePrice(), // Add the selected price based on interval
      subtitle: details.subtitle,
      planInterval: selectedInterval || details.plan_interval, // Pass the selected interval
      board: details.board,
      discount: details.discount,
      country: details.country,
      state: details.state,
    });
    navigate('/account/signUp'); // Navigate to the sign-up page
  };

  const calculatePrice = () => {
    if (selectedInterval === details.plan_interval) {
      return details.one_year_price;
    } else if (selectedInterval === details.plan_interval2) {
      return details.two_year_price;
    } else if (selectedInterval === details.plan_interval3) {
      return details.three_year_price;
    } else {
      return details.one_year_price; // Default price if no interval is selected
    }
  };

  return (
    <Box
      sx={{
        marginTop: { xs: '50px', md: '100px' }, // Adjust margin for mobile screens
        padding: '20px',
        width: '100%', // Full width on mobile
      }}
    >
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              border: '1px solid #fff',
              padding: '10px',
              textAlign: 'center',
              backgroundImage: `url(${ProductCardBG})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Typography variant='h4' sx={{ color: '#fff', fontSize: { xs: '18px', md: '24px' } }}>
              {details.title}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              border: '1px solid #fff',
              padding: '10px',
              textAlign: 'center',
              backgroundImage: `url(${ProductCardBG})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Typography variant='h4' sx={{ color: '#fff', fontSize: { xs: '18px', md: '24px' } }}>
              {details.subtitle}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            onClick={() => handleSelect(details.plan_interval)}
            sx={{
              border:
                selectedInterval === details.plan_interval ? '2px solid #ff0' : '1px solid #fff',
              padding: '10px',
              textAlign: 'center',
              backgroundImage: `url(${ProductCardBG})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              cursor: 'pointer',
            }}
          >
            <Typography variant='h4' sx={{ color: '#fff', fontSize: { xs: '18px', md: '24px' } }}>
              {details.plan_interval}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            onClick={() => handleSelect(details.plan_interval2)}
            sx={{
              border:
                selectedInterval === details.plan_interval2 ? '2px solid #ff0' : '1px solid #fff',
              padding: '10px',
              textAlign: 'center',
              backgroundImage: `url(${ProductCardBG})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              cursor: 'pointer',
            }}
          >
            <Typography variant='h4' sx={{ color: '#fff', fontSize: { xs: '18px', md: '24px' } }}>
              {details.plan_interval2}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            onClick={() => handleSelect(details.plan_interval3)}
            sx={{
              border:
                selectedInterval === details.plan_interval3 ? '2px solid #ff0' : '1px solid #fff',
              padding: '10px',
              textAlign: 'center',
              backgroundImage: `url(${ProductCardBG})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              cursor: 'pointer',
            }}
          >
            <Typography variant='h4' sx={{ color: '#fff', fontSize: { xs: '18px', md: '24px' } }}>
              {details.plan_interval3}
            </Typography>
          </Box>
        </Grid>
        {/* Display price based on selected interval */}

        <Grid item xs={12} md={8}>
          <Box
            sx={{
              marginTop: '50px',
              border: '1px solid #fff',
              padding: '10px',
              textAlign: 'center',
              backgroundImage: `url(${PayBG})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Typography
              variant='h4'
              sx={{ color: '#fff', fontSize: { xs: '20px', md: '26px' } }}
              onClick={() => handleBookClick(details.title, calculatePrice())}
            >
              PAY ₹{calculatePrice()}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductPayment;

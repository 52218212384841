import React, { useEffect, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  Container,
  Grid,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { authActions } from '_store';
import GoogleButton from 'react-google-button';
import { Country, State, City } from 'country-state-city';
import { IndividualForm } from './Login';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import signUp from '_assets/images/sigup.png';
import boywithcloud from '_assets/images/boy_with_cloud.svg';
import { fetchWrapper } from '_helpers';
import { usePayment } from './Product/PaymentContext';
import { useNavigate } from 'react-router-dom';

export { SignUp };

const StickyFormContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  bottom: 0, // Dark blue background
  padding: '0 !important',
  borderRadius: theme.shape.borderRadius,
  color: '#ffffff', // White text color
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const CustomTextField = styled(TextField)(() => ({
  '& .MuiInputLabel-root': {
    color: '#ffffff', // White label color
  },
  '& .MuiInputBase-input': {
    color: '#ffffff', // White text color inside input
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor:
      'linear-gradient(to right, rgb(18 39 67) 0%, rgb(20 36 57) 50%, rgb(44 54 67) 100%) !important',
    borderRadius: '15px',
    '& fieldset': {
      backgroundColor:
        'linear-gradient(to right, rgb(18 39 67) 0%, rgb(20 36 57) 50%, rgb(44 54 67) 100%) !important',
      borderColor: '#736d604d',
    },

    '&:hover fieldset': {
      borderColor: '#736d604d',
      backgroundColor:
        'linear-gradient(to right, rgb(18 39 67) 0%, rgb(20 36 57) 50%, rgb(44 54 67) 100%) !important',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#736d604d',
    },
    '&.Mui-focused .MuiInputBase-input': {
      backgroundColor:
        'linear-gradient(to right, rgb(18 39 67) 0%, rgb(20 36 57) 50%, rgb(44 54 67) 100%) !important',
    },
  },
  '& .MuiFormHelperText-root': {
    backgroundColor:
      'linear-gradient(to right, rgb(18 39 67) 0%, rgb(20 36 57) 50%, rgb(44 54 67) 100%) !important',
    color: '#fff !important',
  },
  // Autofill styles
  '& input:-webkit-autofill': {
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': '#ffffff !important',
  },
}));

const CustomLoadingButton = styled(LoadingButton)(() => ({
  backgroundColor: '#008942', // White background
  color: '#fff', // Dark blue text color
  borderColor: '#736d604d',
  '&:hover': {
    backgroundColor: '#092141', // Light gray on hover
  },
  marginTop: '30px',
}));

const SignUpForm = ({ onSubmit, setView }) => {
  const {
    register,
    handleSubmit,

    formState: { errors, isSubmitting },

    reset,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        student_email: Yup.string().required('Email is required').email('Email is invalid'),
        student_name: Yup.string().required('Name is required'),
        password: Yup.string()
          .required('Password is required')
          .min(8, 'Password must be at least 8 characters long')
          .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
          .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
          .matches(/\d/, 'Password must contain at least one number')
          .matches(
            /[!@#$%^&*(),.?":{}|<>]/,
            'Password must contain at least one special character',
          ),
        // student_age: Yup.number().required('Age is required').positive().integer(),
        student_standard: Yup.string().required('Standard is required'),
        // student_school: Yup.string().required('School is required'),
        student_board: Yup.string().required('Board is required'),
        // address: Yup.string().required('Address is required'),
        // pinCode: Yup.string().required('Pin Code is required'),
        // parent_mobile: Yup.string().required('Parent Mobile is required'),
        student_mobile: Yup.string().required('Student Mobile is required'),
      }),
    ),
  });

  const [showPassword, setShowPassword] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [planList, setPlanList] = useState([]);
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;
  const { paymentData } = usePayment();
  let title = paymentData?.title;
  let subtitle = paymentData?.subtitle;
  let planValue = paymentData?.plan_value;
  let board = paymentData?.board;

  const navigate = useNavigate();

  const handleClick = async (data) => {
    try {
      await onSubmit(data);
      reset();
      setSelectedCountry('');
      setSelectedState('');
      setSelectedCity('');
    } catch (error) {
      console.error('Submission failed:', error);
    }
  };

  const handleSigninSuccess = () => {
    setView('signin');
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let res = await fetchWrapper.get(`${baseUrl}/getPlanCourses`);
        setPlanList(res.result);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    if (planList.length === 0) {
      fetchData();
    }
  }, []);
  const countries = Country.getAllCountries();
  const countryOptions = countries?.map((country) => ({
    label: country.name,
    value: country?.isoCode,
  }));

  const states = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];
  const stateOptions = states?.map((state) => ({
    label: state.name,
    value: state.isoCode,
  }));

  const cities = selectedState ? City.getCitiesOfState(selectedCountry, selectedState) : [];
  const cityOptions = cities?.map((city) => ({
    label: city.name,
    value: city.name,
  }));

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedState('');
    setSelectedCity('');
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedCity('');
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleGoogleCallback = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/api/v1/auth/google`;
  };
  const redirectToProductPage = () => {
    navigate('/product'); // Replace '/product-page' with your actual route
  };

  return (
    <>
      <StickyFormContainer>
        <form onSubmit={handleSubmit(handleClick)}>
          {!title && !subtitle ? (
            <Box>
              <p>Please select a plan</p>
              <CustomLoadingButton
                fullWidth
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
                onClick={redirectToProductPage}
                sx={{ border: '1px solid #736d604d', fontSize: '17px' }}
              >
                Go to Product Page
              </CustomLoadingButton>
              {/* <button onClick={redirectToProductPage}>Go to Product Page</button> */}
            </Box>
          ) : (
            <>
              <GoogleButton onClick={handleGoogleCallback} />

              <text style={{ color: 'white' }}>or</text>
              <hr></hr>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    name='student_email'
                    label='Student Email'
                    variant='outlined'
                    fullWidth
                    {...register('student_email')}
                    error={!!errors.student_email}
                    helperText={errors.student_email?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    name='password'
                    label='Password'
                    type={showPassword ? 'text' : 'password'}
                    variant='outlined'
                    fullWidth
                    {...register('password')}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            sx={{ padding: 0 }}
                          >
                            {showPassword ? (
                              <VisibilityIcon sx={{ color: '#ffffff' }} />
                            ) : (
                              <VisibilityOffIcon sx={{ color: '#ffffff' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    name='student_name'
                    label='Student Name'
                    variant='outlined'
                    fullWidth
                    {...register('student_name')}
                    error={!!errors.student_name}
                    helperText={errors.student_name?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    name='student_age'
                    label='Student Age'
                    type='number'
                    variant='outlined'
                    fullWidth
                    {...register('student_age')}
                    error={!!errors.student_age}
                    helperText={errors.student_age?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    name='student_standard'
                    label='Student Standard'
                    variant='outlined'
                    fullWidth
                    select
                    defaultValue={title && subtitle ? `${title}-${subtitle}` : ''}
                    {...register('student_standard')}
                    error={!!errors.student_standard}
                    helperText={errors.student_standard?.message}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        color: 'white',
                      },
                    }}
                  >
                    {title && subtitle ? (
                      <MenuItem value={`${planValue}`}>
                        {title} - {subtitle}
                      </MenuItem>
                    ) : (
                      planList.map((plan, index) => (
                        <MenuItem key={index} value={plan.plan_name}>
                          {plan.plan_name}
                        </MenuItem>
                      ))
                    )}
                  </CustomTextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    name='student_school'
                    label='Student School'
                    variant='outlined'
                    fullWidth
                    {...register('student_school')}
                    error={!!errors.student_school}
                    helperText={errors.student_school?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    name='student_board'
                    label='Student Board'
                    variant='outlined'
                    fullWidth
                    select
                    {...register('student_board')}
                    error={!!errors.student_board}
                    helperText={errors.student_board?.message}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        color: 'white',
                      },
                    }}
                  >
                    {board ? (
                      <MenuItem value={board}>{board}</MenuItem>
                    ) : (
                      <>
                        <MenuItem value='NEET'>NEET</MenuItem>
                        <MenuItem value='CBSE'>CBSE</MenuItem>
                      </>
                    )}
                  </CustomTextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    name='address'
                    label='Address'
                    variant='outlined'
                    fullWidth
                    {...register('address')}
                    error={!!errors.address}
                    helperText={errors.address?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    name='student_mobile'
                    label='Student Mobile'
                    type='tel'
                    variant='outlined'
                    fullWidth
                    {...register('student_mobile')}
                    error={!!errors.student_mobile}
                    helperText={errors.student_mobile?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    label='Select a Country'
                    variant='outlined'
                    fullWidth
                    select
                    {...register('student_country')}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        color: 'white',
                      },
                    }}
                  >
                    {countryOptions &&
                      countryOptions.length &&
                      countryOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </CustomTextField>
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    label='Select a State'
                    variant='outlined'
                    fullWidth
                    select
                    {...register('state')}
                    value={selectedState}
                    onChange={handleStateChange}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        color: 'white',
                      },
                    }}
                  >
                    {stateOptions.length === 0 ? (
                      <MenuItem disabled>No states available</MenuItem>
                    ) : (
                      stateOptions &&
                      stateOptions.length &&
                      stateOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))
                    )}
                  </CustomTextField>
                </Grid>

                <Grid item xs={6}>
                  <CustomTextField
                    label='Select a City'
                    variant='outlined'
                    fullWidth
                    select
                    {...register('city')}
                    value={selectedCity}
                    onChange={handleCityChange}
                    disabled={!selectedState || cityOptions.length === 0}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        color: 'white',
                      },
                    }}
                  >
                    {cityOptions.length === 0 ? (
                      <MenuItem disabled>No cities available</MenuItem>
                    ) : (
                      cityOptions &&
                      cityOptions.length &&
                      cityOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))
                    )}
                  </CustomTextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    name='pinCode'
                    label='Pin Code'
                    variant='outlined'
                    fullWidth
                    {...register('pinCode')}
                    error={!!errors.pinCode}
                    helperText={errors.pinCode?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    name='parent_mobile'
                    label='Parent Mobile'
                    type='tel'
                    variant='outlined'
                    fullWidth
                    {...register('parent_mobile')}
                    error={!!errors.parent_mobile}
                    helperText={errors.parent_mobile?.message}
                  />
                </Grid>
              </Grid>
              <CustomLoadingButton
                fullWidth
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
                sx={{ border: '1px solid #736d604d', fontSize: '17px' }}
              >
                Sign Up
              </CustomLoadingButton>
              <Typography sx={{ marginTop: '10px', textAlign: 'center', color: '#ffffff' }}>
                Already have an account?{' '}
                <Typography
                  component='span'
                  sx={{ color: '#90caf9', cursor: 'pointer', fontSize: '16px' }}
                  onClick={handleSigninSuccess}
                >
                  Sign In
                </Typography>
              </Typography>
            </>
          )}
        </form>
      </StickyFormContainer>
    </>
  );
};

function SignUp() {
  const dispatch = useDispatch();
  const [view, setView] = useState('signin');
  const alert = useSelector((x) => x.alert.value);
  // const navigate = useNavigate();

  const StyledRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    backgroundImage: `url(${signUp})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '92vh',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
      flexDirection: 'column',
      margin: '0 0px',
    },
  }));

  const StyledSection = styled('div')(({ theme }) => ({
    width: '50%',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2vh',
      width: '100%',
      height: '100%',
    },
  }));

  const StyledContent = styled('div')(({ theme }) => ({
    flex: 1,
    maxWidth: 1000,
    margin: 'auto',
    padding: '1px 30px !important',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
    },
  }));

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid #736d604d',
    color: 'white',
    margin: '20px 20px',
    borderRadius: '15px',
    fontSize: '17px',
  }));

  const StyledToggleButton = styled(ToggleButton)(() => ({
    width: '100%',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'none',
    borderRadius: '15px',
    color: 'white',
    '&.Mui-selected': {
      backgroundColor: '#092141',
      color: 'white',
      borderColor: 'white',
      borderBottomLeftRadius: '15px !important',
      borderTopLeftRadius: '15px !important',
      borderBottomRightRadius: '15px !important',
      borderTopRightRadius: '15px !important',
    },
  }));

  // const handleSignupSuccess = () => {
  //   setView('signup');
  // };

  const handleSubmit = (data) => {
    dispatch(
      authActions.signup({
        ...data,
      }),
    )
      .then(() => {
        toast.success('Account created successfully');
      })
      .catch((error) => {
        console.error('Signup failed:', error);
        toast.error('Signup failed');
      });
  };

  const handleSubmitIndividual = (data) => {
    dispatch(authActions.login(data));
  };

  return (
    <StyledRoot>
      <StyledSection>
        <img
          src={boywithcloud}
          width={'100%'}
          height={'95%'}
          alt='login'
          onError={(e) => {
            console.warn('Error loading image:', e);
            e.target.style.display = 'none';
          }}
          style={{ marginTop: '45px' }}
        />
      </StyledSection>
      <Container
        maxWidth='sm'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          padding: '10px !important',
          border: '1px solid #736d604d',
          background:
            'linear-gradient(to right, rgb(18 39 67) 0%, rgb(20 36 57) 50%, rgb(44 54 67) 100%)',

          borderRadius: '35px',
          margin: '30px 40px 25px auto!important',
        }}
      >
        <StyledToggleButtonGroup
          value={view}
          exclusive
          onChange={(event, newView) => {
            if (newView !== null) {
              setView(newView);
            }
          }}
        >
          <StyledToggleButton value='signin'>Sign In</StyledToggleButton>
        </StyledToggleButtonGroup>
        <StyledContent>
          <div className={`alert ${alert && alert.type}`}>{alert && alert.message}</div>
          {view === 'signup' ? (
            <SignUpForm onSubmit={handleSubmit} view={view} setView={setView} />
          ) : (
            <>
              <IndividualForm onSubmit={handleSubmitIndividual} />

              {/* <Typography sx={{ marginTop: '10px', textAlign: 'center', color: '#ffffff' }}>
                Don’t have an account?{' '}
                <Typography
                  component='span'
                  sx={{ color: '#90caf9', cursor: 'pointer', fontSize: '16px' }}
                  onClick={handleSignupSuccess}
                >
                  Sign Up
                </Typography>
              </Typography> */}
            </>
          )}
        </StyledContent>
      </Container>
    </StyledRoot>
  );
}

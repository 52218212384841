import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from '_helpers';
import { useSelector } from 'react-redux';
import TN_Math from '_assets/images/10th-Math-Book.webp';
import NEET_Physics from '_assets/images/Physics.webp';
import NEET_Chemistry from '_assets/images/Chemistry.webp';
import NEET_Biology from '_assets/images/Biology.webp';
import TN_Science from '_assets/images/10th-science-Book.webp';
import SAT_Subject1 from '_assets/images/SAT_logo.png';
import SAT_Subject2 from '_assets/images/SAT_logo.png';
import SAT_Subject3 from '_assets/images/SAT_logo.png';
import NEET_TEST from '_assets/images/NEET_TEST_logo.png';
import { Fullscreen } from '@mui/icons-material';

export { Steps };

function Steps({ onBack, isNEET, isSAT, isNEETTEST, isNCERT }) {
  const navigate = useNavigate();
  const [textbooks, setTextbooks] = useState([]);
  const auth = useSelector((x) => x.auth.value);

  const CBSETextbooks = [
    {
      image: 'https://ncert.nic.in/textbook/pdf/jesc1cc.jpg',
      standard: 10,
      subject: 'science',
    },
    {
      image: 'https://ncert.nic.in/textbook/pdf/jemh1cc.jpg',
      standard: 10,
      subject: 'maths',
    },
    {
      image: 'https://ncert.nic.in/textbook/pdf/jeff1cc.jpg',
      standard: 10,
      subject: 'english',
    },
    {
      image: 'https://ncert.nic.in/textbook/pdf/iesc1cc.jpg',
      standard: 9,
      subject: 'science',
    },
    {
      image: 'https://ncert.nic.in/textbook/pdf/iemh1cc.jpg',
      standard: 9,
      subject: 'maths',
    },
    {
      image: 'https://ncert.nic.in/textbook/pdf/iebe1cc.jpg',
      standard: 9,
      subject: 'english',
    },
    {
      image: 'https://ncert.nic.in/textbook/pdf/hesc1cc.jpg',
      standard: 8,
      subject: 'science',
    },
    {
      image: 'https://ncert.nic.in/textbook/pdf/hemh1cc.jpg',
      standard: 8,
      subject: 'maths',
    },
    {
      image: 'https://ncert.nic.in/textbook/pdf/hehd1cc.jpg',
      standard: 8,
      subject: 'english',
    },
  ];

  const TNTextbooks = [
    {
      image: TN_Science,
      standard: 10,
      subject: 'science',
    },
    {
      image: TN_Math,
      standard: 10,
      subject: 'maths',
    },
  ];

  const NEETSubjects = [
    {
      subject: 'Physics',
      image: NEET_Physics,
    },
    {
      subject: 'Chemistry',
      image: NEET_Chemistry,
    },
    {
      subject: 'Biology',
      image: NEET_Biology,
    },
  ];

  const SATSubjects = [
    {
      subject: 'maths',
      image: SAT_Subject1,
    },
    {
      subject: 'physics',
      image: SAT_Subject2,
    },
    {
      subject: 'chemistry',
      image: SAT_Subject3,
    },
  ];

  const NEETTEST = [
    {
      subject: 'All_Subjects',
      image: NEET_TEST,
    },
  ];

  useEffect(() => {
    if (auth && auth.board) {
      if (isNEET) {
        setTextbooks(NEETSubjects);
      } else if (isNEETTEST) {
        setTextbooks(NEETTEST);
      } else if (isSAT) {
        setTextbooks(SATSubjects);
      } else if (isNCERT) {
        setTextbooks(CBSETextbooks);
      } else if (auth.board === 'CBSE') {
        setTextbooks(CBSETextbooks);
      } else if (auth.board === 'Matric') {
        if (auth.state === 'Tamil Nadu') {
          setTextbooks(TNTextbooks);
        } else {
          setTextbooks([]);
        }
      }
    }
  }, [auth, isNEET, isSAT, isNCERT]);

  const handleBookClick = (standard, subject) => {
    navigate(`/dashboard/standard/${standard}/subject/${subject}`);
  };

  const handleNEETClick = (subject) => {
    console.log(subject);
    navigate(`/dashboard/NEET/subject/${subject}`, { state: { isNEET } });
  };

  const handleNEETTESTClick = (subject) => {
    console.log(subject);
    navigate(`/dashboard/NEET_TEST/subject/${subject}`, { state: { isNEETTEST } });
  };

  const handleSATClick = (subject) => {
    navigate(`/dashboard/SAT/subject/${subject}`, { state: { isSAT } });
  };

  const handleClick = (book) => {
    if (isNEET) {
      handleNEETClick(book.subject);
    } else if (isNEETTEST) {
      handleNEETTESTClick(book.subject);
    } else if (isSAT) {
      handleSATClick(book.subject);
    } else if (isNCERT) {
      handleBookClick(book.standard, book.subject);
    } else {
      handleBookClick(book.standard, book.subject);
    }
  };

  return (
    <Box
      sx={{ width: '90%', padding: '16px', margin: 'auto' }}
      className={isNEET || isSAT ? 'mt-4' : ''}
    >
      <Button onClick={onBack} className='btn btn-secondary text-end'>
        Back
      </Button>
      <Grid container spacing={2} className='p-3'>
        {textbooks &&
          textbooks.map((book, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              key={index}
              style={{ height: '100%', marginTop: '10px' }}
            >
              <div style={{ margin: isNEET || isSAT ? '20px' : '' }}>
                <img
                  src={book.image}
                  alt={`textbook-${index}`}
                  className={isNEET || isSAT ? 'shadow ' : ''}
                  style={{
                    width: '100%',
                    height:
                      (isNEET || isSAT) && !Fullscreen
                        ? '400px'
                        : isNEET || isSAT
                        ? 'auto'
                        : '300px',
                    objectFit: isNEET || isSAT ? 'cover' : 'contain',
                    borderRadius: isNEET || isSAT ? '20px' : '',
                  }}
                  onClick={() => handleClick(book)}
                  onError={(e) => {
                    console.warn('Error loading image:', e);
                    e.target.style.display = 'none';
                  }}
                />
                <div className='text-center textbook-label'>
                  {book.standard && (
                    <div>
                      {capitalizeFirstLetter('standard:') +
                        ' ' +
                        capitalizeFirstLetter(book.standard)}
                    </div>
                  )}
                  {book.subject && (
                    <div>
                      {capitalizeFirstLetter('subject:') +
                        ' ' +
                        capitalizeFirstLetter(book.subject)}
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}

export default Steps;

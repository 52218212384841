import React, { useEffect, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
// import GoogleButton from 'react-google-button';
import { styled } from '@mui/material/styles';
import { fetchWrapper } from '_helpers';
import { GoogleLogin } from '@react-oauth/google';
export { IndividualForm };
import { authActions } from '_store';
import { useDispatch } from 'react-redux';
import { jwtDecode } from 'jwt-decode';

function IndividualForm({ onSubmit }) {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required'),
      }),
    ),
  });
  const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [planList, setPlanList] = useState([]);
  const [isNewUser, setIsNewUser] = useState(false);

  const handleClick = (data) => {
    data.board = ''; // Use the board state value
    data.state = null; // Set state to null regardless of the plan
    data.isNewUser = isNewUser;
    // Call the onSubmit function with the updated data
    onSubmit(data);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let res = await fetchWrapper.get(`${baseUrl}/getPlanCourses`);
        setPlanList(res.result);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    if (planList.length === 0) {
      fetchData();
    }
  }, []);

  const handleMobileLoginClick = () => {
    navigate('/account/individual/MobileLogin');
  };

  const CustomTextField = styled(TextField)(() => ({
    '& .MuiInputLabel-root': {
      color: '#ffffff', // White label color
    },
    '& .MuiInputBase-input': {
      color: '#ffffff', // White text color inside input
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor:
        'linear-gradient(to right, rgb(18 39 67) 0%, rgb(20 36 57) 50%, rgb(44 54 67) 100%)',
      borderRadius: '15px',
      '& fieldset': {
        borderColor: '#736d604d', // White border color
      },
      '&:hover fieldset': {
        borderColor: '#736d604d', // White border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#736d604d', // White border color when focused
      },
    },
    // Autofill styles
    '& input:-webkit-autofill': {
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': '#ffffff !important',
    },
  }));

  const CustomLoadingButton = styled(LoadingButton)(() => ({
    color: '#fff', // Dark blue text color
    borderColor: '#736d604d',
    '&:hover': {
      backgroundColor: '#092141', // Light gray on hover
    },
    marginTop: '30px',
  }));

  const responseMessage = (response) => {
    try {
      const decoded = jwtDecode(response.credential);
      dispatch(authActions.login({ email: decoded.email, googleToken: response.credential }));
    } catch (error) {
      console.error('Error in Google Login:', error);
    }
  };

  const errorMessage = (error) => {
    console.log(error);
  };
  return (
    <form onSubmit={handleSubmit(handleClick)} style={{ position: 'relative' }}>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        flexDirection={{ xs: 'column', sm: 'row' }}
        gap={{ xs: '10px' }}
      >
        <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
        <Button
          variant='outlined'
          color='primary'
          onClick={handleMobileLoginClick}
          sx={{
            marginLeft: 2,
            cursor: 'pointer',
            fontSize: '16px',
            background: '#da4040',
            color: 'white',
            borderRadius: 'none',
            borderColor: 'none',
            '&:hover': {
              backgroundColor: '#b43b3b',
            },
          }}
        >
          Mobile Login
        </Button>
      </Box>
      <text style={{ color: 'white' }}>or</text>
      <hr></hr>
      <CustomTextField
        className='login-field'
        name='email'
        label='Email address'
        variant='outlined'
        fullWidth
        required
        {...register('email')}
        error={!!errors.email}
        helperText={errors.email?.message}
        sx={{ marginTop: '20px' }}
      />

      <CustomTextField
        name='password'
        label='Password'
        type={showPassword ? 'text' : 'password'}
        variant='outlined'
        fullWidth
        required
        {...register('password')}
        error={!!errors.password}
        helperText={errors.password?.message}
        sx={{ marginTop: '20px' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={() => setShowPassword(!showPassword)} sx={{ padding: 0 }}>
                {showPassword ? (
                  <VisibilityIcon sx={{ color: '#ffffff' }} />
                ) : (
                  <VisibilityOffIcon sx={{ color: '#ffffff' }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={isNewUser}
            onChange={() => setIsNewUser((prev) => !prev)} // Toggle new user state
            color='primary'
            sx={{
              color: '#fff',
              display: 'flex',
              justifyContent: 'flex-start',
              marginLeft: '0px',
            }}
          />
        }
        label='New User'
        sx={{
          color: 'white',
          marginTop: '20px',
          marginBottom: 2,
          // color: '#fff',
          display: 'flex',
          justifyContent: 'flex-start',
          marginLeft: '0px',
        }}
      />

      <CustomLoadingButton
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        loading={isSubmitting}
        sx={{
          backgroundColor: '#008942',
          marginTop: '20px',
          border: '1px solid #736d604d',
          fontSize: '17px',
        }}
      >
        Sign In
      </CustomLoadingButton>
    </form>
  );
}

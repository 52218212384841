import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import IndividualHome from './../individualDashboard/individualHome';
import { NeetHome } from './../individualNeetDashboard/neetHome';
import { useNavigate } from 'react-router-dom';
import { usePayment } from 'account/individual/Product/PaymentContext';

const Dashboard = () => {
  // Dashboard
  const { paymentData } = usePayment();
  let title = paymentData.title;
  const user = useSelector((x) => x.auth.value);
  const { plan_courses } = user || {};
  const navigate = useNavigate();
  useEffect(() => {
    if (!plan_courses && title) {
      navigate('/payment');
    }
    if (!title) {
      navigate('/product');
    }
    if (plan_courses) {
      navigate('/Dashboard/?login_successful=true', { state: { plan_courses } });
    }
  }, [plan_courses, navigate]);
  return (
    <div>
      {plan_courses === 'NEET_PREPARATION' && <NeetHome />}
      {['CLASS_10', 'CLASS_9', 'CLASS_8'].includes(plan_courses) && <IndividualHome />}
    </div>
  );
};

export default Dashboard;
